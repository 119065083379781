import React from 'react'
import { Icon } from '@bespin-ui/react-ui-components'
import styled from 'styled-components'
import { BiCaretDown, BiMinus } from 'react-icons/bi'

interface IPanelProps {
    title?: string,
    children: React.ReactNode,
    className?: string,
    titleContainerClassName?: string,
    titleClassName?: string,
    /** children must be {PanelBody} in order to collapse contents */
    collapsible?: boolean,
    defaultCollapsibleState?: boolean,
}

const PanelContainer = styled.div`
    border: 1px solid #ddd;
    position: relative;
`

const IconWrapper = styled.div`
  position: absolute;
  left: auto;
  top: 0.2rem;
  right: 0.2rem;
  cursor: pointer;
`

const PanelTitle = styled.div`
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    border-bottom: 1px solid #ddd;
    padding: 10px 15px;
`

const PanelBody = styled.div`
    background-color: white;
    padding: 15px;
`

function Panel({
  title, children, className, titleClassName, titleContainerClassName,
  collapsible, defaultCollapsibleState,
}: IPanelProps) {
  const [isCollapsed, setIsCollapsed] = React.useState(defaultCollapsibleState)

  const newChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === PanelBody && isCollapsed) {
      return React.cloneElement(child as React.ReactElement, { style: { display: 'none' } })
    }
    return child
  })
  return (
    <PanelContainer className={className}>
      {collapsible && (
      <IconWrapper onClick={() => setIsCollapsed(!isCollapsed)}>
        {isCollapsed
          ? <Icon icon={<BiCaretDown size={35} />} />
          : <Icon icon={<BiMinus size={35} />} />}
      </IconWrapper>
      ) }
      {(title != null) ? (
        <PanelTitle className={titleContainerClassName}>
          <div className={titleClassName ?? 'title is-5'}>{title}</div>
        </PanelTitle>
      ) : ''}
      {newChildren}
    </PanelContainer>
  )
}

Panel.defaultProps = {
  title: undefined,
  className: undefined,
  titleClassName: undefined,
  titleContainerClassName: undefined,
  collapsible: false,
  defaultCollapsibleState: false,
}

export { PanelBody }

export default Panel
