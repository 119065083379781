import classNames from 'classnames'
import React from 'react'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as bulmaToast from 'bulma-toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import { Button } from '@bespin-ui/react-ui-components'
import { AxiosError } from 'axios'
import { Form, Icon } from '../../../components/bulma'
import {
  ConfirmModal, Label, Panel, PanelBody,
} from '../../../components'
import { IOracleFormFields, IPrimaryFields } from '../../../types/createDatabase'
import { useCreateAWSOracle } from '../../../hooks/api/databases'
import TextTooltip from '../../../components/Tooltip'

const charRegex = /^[a-zA-Z]*$/
/* Leading and ending hyphens are not allowed */
const alphanumericWithSingleHyphenRegex = /^([a-zA-Z0-9]+[-]{1})*[a-zA-Z0-9]+$/
/* Leading and ending underscores are not allowed */
const alphanumericWithSingleUnderscoreRegex = /^([a-zA-Z0-9]+[_]{1})*[a-zA-Z0-9]+$/

const schema = yup.object({
  capacity: yup.number().typeError('Must be an integer.').positive().required('is required')
    .min(40, 'Storage size must be more than 39 GB'),
  databaseName: yup.string().required('is required')
    .max(8, 'Must be shorter than 9 characters')
    .test('databaseName-validation', (value, { createError, path }) => {
      if (value == null || (value && value.length === 0)) {
        return createError({ path, message: 'is required' })
      }
      if (!charRegex.test(value[0])) {
        return createError({ path, message: 'Must start with a letter.' })
      }
      if (!alphanumericWithSingleHyphenRegex.test(value)) {
        return createError({ path, message: 'Must only contain alphanumeric characters. Hyphen is also allowed. Can\'t contain two consecutive hyphen.' })
      }
      return true
    }),
  backupRetentionPeriod: yup.number().typeError('Must be an integer.').positive().required('is required')
    .min(7)
    .max(35, 'Must be no more than 35 Days.'),
  backupWindow: yup.string().required(),
  allowMajorVersionUpgrade: yup.boolean().required('is required'), // false
  autoMinorVersionUpgrade: yup.boolean().required('is required'), // true,
  customerAdminUsername: yup.string().required('is required')
    .min(1)
    .max(16, 'Must be shorter than 17 characters.')
    .test('adminUsername-validation', (value, { createError, path }) => {
      if (value == null || (value && value.length === 0)) {
        return createError({ path, message: 'is required' })
      }
      if (!charRegex.test(value[0])) {
        return createError({ path, message: 'Must start with a letter.' })
      }
      if (!alphanumericWithSingleUnderscoreRegex.test(value)) {
        return createError({ path, message: 'Must only contain alphanumeric characters. Underscore is also allowed. Can\'t contain two consecutive underscore.' })
      }
      return true
    }),
  customerAdminPassword: yup.string()
    .required('is required')
    .min(8, 'Must be more than 7 characters.')
    .max(16, 'Must be less than 17 characters.')
    .matches(
      /^[^'"/@\s]*$/,
      { message: 'At least 8 printable ASCII characters. Can\'t contain any of the following: /(forward slash), \'(single quote), "(double quote) and @(at sign).' },
    ),
  license: yup.string().required(),
  timezone: yup.string().required(),
  region: yup.string().required(),
  identifier: yup.string().required(),
  multiAz: yup.boolean(),
  maintenanceWindow: yup.string()
    .required(),
  engineVersion: yup.string(),
  oracleEngine: yup.string(),
})

interface IProps {
  parentProps?: Partial<IPrimaryFields>,
  taxonomyId: string | undefined
}

const backupAndMaintenanceWindows = [
  { backupWindow: '04:00-05:59', maintenanceWindow: 'Wed:06:00-Wed:08:00' },
  { backupWindow: '05:00-06:59', maintenanceWindow: 'Wed:07:00-Wed:09:00' },
  { backupWindow: '06:00-07:59', maintenanceWindow: 'Wed:08:00-Wed:10:00' },
]

function CreateOracleDatabaseForm({ parentProps, taxonomyId }: IProps) {
  const navigation = useNavigate()
  const [openConfirmModal, setOpenConfirmModal] = React.useState(false)

  const [showPassword, setShowPassword] = React.useState(false)

  const [isCustomBackupAndMaintenanceWindow,
    setIsCustomBackupAndMaintenanceWindow] = React.useState(false)

  const {
    formState: { errors, isValid }, register, watch, setValue, handleSubmit,
  } = useForm<IOracleFormFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      backupWindow: backupAndMaintenanceWindows[0].backupWindow,
      backupRetentionPeriod: 7,
      autoMinorVersionUpgrade: true,
      allowMajorVersionUpgrade: false,
      multiAz: false,
      license: 'license-included',
      timezone: 'America/New_York',
      region: 'us-east-1',
      maintenanceWindow: backupAndMaintenanceWindows[0].maintenanceWindow,
      engineVersion: '19.0.0.0.ru-2021-10.rur-2021-10.r1',
      oracleEngine: 'oracle-se2',
      identifier: 'Production',
    },
  })

  const { mutate, isLoading } = useCreateAWSOracle()

  const values = watch()

  const onSubmit = () => {
    if (!isValid
      || !taxonomyId
      || !parentProps?.dataCenter
      || !parentProps.providerName) {
      return
    }
    setOpenConfirmModal(true)
  }

  const closeModal = (): void => setOpenConfirmModal(false)

  // identifier is set Production, multi_az is must set to true
  React.useEffect(() => {
    if (values.identifier === 'Production') {
      setValue('multiAz', true)
    }
  }, [setValue, values.identifier])

  return (
    <>
      <ConfirmModal
        isReverseActionButtonsPosition
        isOpen={openConfirmModal}
        title="Are you sure you want to create ?"
        description="You are about to create the database , you can not undo this action, are you sure you want to continue?"
        handleClose={closeModal}
        okayButton={(
          <Button
            isDisabled={isLoading}
            onClick={() => {
              const databaseParams = {
                dataCenter: parentProps?.dataCenter,
                providerName: parentProps?.providerName,
                serviceOffering: '6181339C-0EE2-47A0-B18C-20F848E13303',
                engine: values.oracleEngine,
                engineVersion: values.engineVersion,
                identifier: values.identifier,
                dbName: values.databaseName,
                storageSize: values.capacity,
                adminUsername: values.customerAdminUsername,
                adminPassword: values.customerAdminPassword,
                optionTimezoneZone: values.timezone,
                region: values.region,
                license: values.license,
                minorVersionUpgrade: values.autoMinorVersionUpgrade,
                majorVersionUpgrade: values.allowMajorVersionUpgrade,
                backupRetention: values.backupRetentionPeriod,
                backupWindow: values.backupWindow,
                maintenanceWindow: values.maintenanceWindow,
                multiAz: values.multiAz,
                taxonomyId: Number(taxonomyId),
              }
              mutate(databaseParams, {
                onError: (error: unknown) => {
                  const err = error as AxiosError<{ message: string }>
                  let message = err.response?.data?.message
                  message = err.isAxiosError && (message && message !== 'null') ? message : 'Unexpected error occurred!. Check your input and try again or contact admin.'
                  bulmaToast.toast({
                    message,
                    type: 'is-danger',
                    dismissible: true,
                    duration: 6000,
                    position: 'bottom-right',
                    animate: { in: 'fadeIn', out: 'fadeOut' },
                  })
                  closeModal()
                },
                onSuccess: () => {
                  bulmaToast.toast({
                    message: `<b>${values?.databaseName}</b> Database is successfully created`,
                    type: 'is-success',
                    dismissible: true,
                    duration: 6000,
                    position: 'bottom-right',
                    animate: { in: 'fadeIn', out: 'fadeOut' },
                  })
                  navigation(-1)
                },
              })
            }}
            variant="primary"
            label="Create Database"
          />
        )}
        cancelButton={(
          <Button
            data-testid="modalCancelButton"
            isDisabled={isLoading}
            color="default"
            onClick={closeModal}
            label="Cancel"
          />
        )}
      />

      <Form.Field>
        <Label label="Region" />
        <Form.Control>
          <Form.Select
            id="region"
            {...register('region')}
            value={values.region ?? ''}
            fullwidth
          >
            <option value="us-east-1">US East 1</option>
            <option value="us-west-2" disabled>US West 2</option>
          </Form.Select>
        </Form.Control>
      </Form.Field>

      <Form.Field>
        <Label label="Database Name" required for="databaseName" />
        <Form.Control>
          <input className={classNames('input', { 'is-danger': errors.databaseName != null })} {...register('databaseName')} id="databaseName" />
        </Form.Control>
        {errors.databaseName && <Form.Help color="danger">{errors.databaseName.message}</Form.Help>}
      </Form.Field>

      <Form.Field>
        <Label label="Admin Username" required for="customerAdminUsername" />
        <Form.Control>
          <input className={classNames('input', { 'is-danger': errors.customerAdminUsername != null })} {...register('customerAdminUsername')} id="customerAdminUsername" />
        </Form.Control>
        <Form.Help color="danger">{errors.customerAdminUsername ? errors.customerAdminUsername.message : ''}</Form.Help>
      </Form.Field>

      <Form.Field>
        <Label label="Admin Password" required for="customerAdminPassword" />
        <Form.Control>
          <input type={showPassword ? 'text' : 'password'} className={classNames('input', { 'is-danger': errors.customerAdminPassword != null })} {...register('customerAdminPassword')} id="customerAdminPassword" />
          <Icon align="right">
            <FontAwesomeIcon icon={faEye} color="rgb(74, 74, 74)" cursor="pointer" pointerEvents="initial" onClick={() => setShowPassword(!showPassword)} />
          </Icon>
        </Form.Control>
        <Form.Help color="danger">{errors.customerAdminPassword ? errors.customerAdminPassword.message : ''}</Form.Help>
      </Form.Field>

      <Form.Field>
        <Label label="Storage Size" required />
        <Form.Control>
          <input className={classNames('input', { 'is-danger': errors.capacity != null })} {...register('capacity')} id="capacity" />
        </Form.Control>
        <Form.Help color="danger">{errors.capacity != null ? errors.capacity.message : ''}</Form.Help>
      </Form.Field>

      <Form.Field horizontal mt={5} mb={0} alignItems="baseline">
        <Form.Field.Label textAlign="left" className="mr-0 title is-6">Oracle Engine</Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Radio
                {...register('oracleEngine')}
                value="oracle-se2"
                checked={values.oracleEngine === 'oracle-se2'}
              >
                Oracle SE2
              </Form.Radio>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal alignItems="baseline" mb={0}>
        <Form.Field.Label textAlign="left" className="mr-0 title is-6">Engine Version</Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Radio
                {...register('engineVersion')}
                value="19.0.0.0.ru-2021-10.rur-2021-10.r1"
              >
                19c
              </Form.Radio>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Panel titleClassName="" title="Identifier">
        <PanelBody>
          <Form.Control className="mb-2">
            <Form.Radio {...register('identifier')} value="Production" checked={values.identifier === 'Production'}>Production</Form.Radio>
          </Form.Control>
          <Form.Control>
            <Form.Radio {...register('identifier')} value="Non-Production" checked={values.identifier === 'Non-Production'}>Non-Production</Form.Radio>
          </Form.Control>
        </PanelBody>
      </Panel>

      <Form.Field horizontal mt={5} mb={0} alignItems="baseline">
        <Form.Field.Label textAlign="left" className="mr-0 mb-0 title is-6">Multi-AZ</Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Checkbox
                {...register('multiAz')}
                onChange={(e) => {
                  setValue('multiAz', e.target.checked)
                }}
                checked={values.multiAz ?? false}
                disabled={values.identifier === 'Production'}
              >
                {values.multiAz ? 'Active' : 'Inactive'}
              </Form.Checkbox>
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field>
        <Label label="Timezone" />
        <Form.Control>
          <Form.Select
            id="timezone"
            {...register('timezone')}
            value={values.timezone ?? ''}
            fullwidth
          >
            <option value="America/New_York">America/New_York (EST)</option>
            <option value="America/Los_Angeles">America/Los_Angeles (PST)</option>
            <option value="UTC">UTC</option>
          </Form.Select>
        </Form.Control>
      </Form.Field>

      <Form.Field>
        <Label label="License Model" />
        <Form.Control>
          <Form.Select
            id="license"
            fullwidth
            {...register('license')}
            value={values.license ?? ''}
          >
            <option value="license-included">License Included</option>
            <option value="BYOL">BYOL (Bring your Own License)</option>
          </Form.Select>
        </Form.Control>
      </Form.Field>

      <Form.Field horizontal mt={5} mb={0} alignItems="baseline">
        <Form.Field.Label textAlign="left" className="mr-0 title is-6">Allow Major Version Upgrade</Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Checkbox
                {...register('allowMajorVersionUpgrade')}
                onChange={(e) => {
                  setValue('allowMajorVersionUpgrade', e.target.checked)
                }}
                checked={values.allowMajorVersionUpgrade ?? false}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Form.Field horizontal alignItems="baseline">
        <Form.Field.Label textAlign="left" className="mr-0 mb-4 title is-6">Auto Minor Version Upgrade</Form.Field.Label>
        <Form.Field.Body>
          <Form.Field>
            <Form.Control>
              <Form.Checkbox
                {...register('autoMinorVersionUpgrade')}
                onChange={(e) => {
                  setValue('autoMinorVersionUpgrade', e.target.checked)
                }}
                checked={values.autoMinorVersionUpgrade ?? false}
              />
            </Form.Control>
          </Form.Field>
        </Form.Field.Body>
      </Form.Field>

      <Panel title="Advanced" collapsible defaultCollapsibleState className="" titleClassName="">
        <PanelBody>
          <Form.Field>
            <Label label="Backup Retention Period" className="mt-1" marginless />
            <Form.Control>
              <input
                className={classNames('input', { 'is-danger': errors.backupRetentionPeriod != null })}
                {...register('backupRetentionPeriod')}
                id="backupRetentionPeriod"
              />
            </Form.Control>
          </Form.Field>

          <Panel title="Maintenance Window & Backup Window" titleClassName="" className="mt-5">
            <PanelBody>
              {backupAndMaintenanceWindows.map((data) => {
                const backupWindow = data.backupWindow.replaceAll('-', ' - ')
                const maintenanceWindow = data.maintenanceWindow.replaceAll('-', ' - ').replaceAll('Wed:', 'Wed: ')
                const string = `Backup Window: ${backupWindow} UTC. Maintenance Window: ${maintenanceWindow} UTC`
                return (
                  <Form.Control className="mb-1" key={data.backupWindow}>
                    <Form.Radio
                      value={JSON.stringify(data)}
                      checked={!isCustomBackupAndMaintenanceWindow
                      && JSON.stringify(data) === JSON.stringify({
                        backupWindow: values.backupWindow,
                        maintenanceWindow: values.maintenanceWindow,
                      })}
                      onChange={(e) => {
                        const { value } = e.target
                        const raw = JSON.parse(value)
                        setValue('backupWindow', raw.backupWindow)
                        setValue('maintenanceWindow', raw.maintenanceWindow)
                        if (isCustomBackupAndMaintenanceWindow) {
                          setIsCustomBackupAndMaintenanceWindow(false)
                        }
                      }}
                    >
                      {string}
                    </Form.Radio>
                  </Form.Control>
                )
              })}

              <Form.Control>
                <Form.Radio
                  value="Custom Values"
                  checked={isCustomBackupAndMaintenanceWindow}
                  onChange={() => {
                    setIsCustomBackupAndMaintenanceWindow(!isCustomBackupAndMaintenanceWindow)
                  }}
                >
                  Custom Values
                </Form.Radio>
              </Form.Control>
              {isCustomBackupAndMaintenanceWindow && (
                <Panel title="Custom Values" titleClassName="" className="mt-4">
                  <PanelBody>
                    <Form.Field>
                      <Label
                        label="Backup Window"
                        for="backupWindow"
                        className="mt-0"
                        marginless
                        required
                        popover={(
                          <TextTooltip>
                            Please
                            {' '}
                            <a
                              href="https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_WorkingWithAutomatedBackups.html#USER_WorkingWithAutomatedBackups.BackupWindow"
                              target="_blank"
                              rel="noreferrer"
                            >
                              review this AWS page
                            </a>
                            {' '}
                            on how to pass a Backup Window value
                          </TextTooltip>
                        )}
                        isInteractivePopover
                      />
                      <Form.Control>
                        <Form.Input id="backupWindow" {...register('backupWindow')} value={values.backupWindow ?? ''} />
                      </Form.Control>
                    </Form.Field>
                    <Form.Field>
                      <Label
                        label="Maintenance Window"
                        for="maintenanceWindow"
                        className="mt-1"
                        marginless
                        required
                        popover={(
                          <TextTooltip>
                            Please
                            {' '}
                            <a
                              href="https://docs.aws.amazon.com/AmazonRDS/latest/UserGuide/USER_UpgradeDBInstance.Maintenance.html#Concepts.DBMaintenance"
                              target="_blank"
                              rel="noreferrer"
                            >
                              review this AWS page
                            </a>
                            {' '}
                            on how to pass a Maintenance Window value
                          </TextTooltip>
                        )}
                        isInteractivePopover
                      />
                      <Form.Control>
                        <Form.Input id="maintenanceWindow" {...register('maintenanceWindow')} value={values.maintenanceWindow ?? ''} />
                      </Form.Control>
                    </Form.Field>
                  </PanelBody>
                </Panel>
              )}
            </PanelBody>
          </Panel>
        </PanelBody>
      </Panel>

      <Form.Field className="is-flex is-justify-content-space-between mt-6">
        <Button
          label="Cancel"
          variant="secondary"
          onClick={() => { navigation(-1) }}
        />
        <Button
          isDisabled={!isValid
            || !taxonomyId
            || !parentProps?.dataCenter
            || !parentProps.providerName}
          label="Submit"
          variant="primary"
          onClick={handleSubmit(onSubmit)}
        />
      </Form.Field>
    </>
  )
}

CreateOracleDatabaseForm.defaultProps = {
  parentProps: undefined,
}

export default CreateOracleDatabaseForm
