import React, { useMemo } from 'react'
import {
  Table as BespinTable, ButtonGroup, Button, Icon,
} from '@bespin-ui/react-ui-components'
import styled from 'styled-components'
import {
  getCoreRowModel, useReactTable,
  ColumnDef,
  flexRender,
  Column,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
} from '@tanstack/react-table'
import {
  BiChevronLeft, BiChevronRight,
  BiChevronsLeft, BiChevronsRight,
  BiCaretDown, BiCaretUp,
} from 'react-icons/bi'

const TableContainer = styled.div`
  margin: -15px;
  padding: 15px;
  padding-top: var(--bespin-lib-measure-26);
  overflow-x: auto;
`

const TableWrapper = styled.div`
  min-width: 1300px;
  border-bottom: 2px solid var(--bespin-lib-color-brand-primary);
  height: 587px;
  overflow-y: auto;
  box-shadow: 0 0 20px rgb(0 0 0 / 25%);
  table {
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin: 0;
    tbody tr:last-of-type {
      border-bottom: none;
    }
    td, th {
      vertical-align: middle !important; 
      width: 100%;
      color: unset;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: var(--bespin-lib-measure-12)  var(--bespin-lib-measure-8);
    }
    td:first-child, th:first-child {
      padding-left: var(--bespin-lib-measure-16);
    }
  } 
  `

const Input = styled.input`
  width: 100%;
  display: block;
  margin-top: 1rem;
  outline: none;
  border-radius: 0.5rem;
  height: 2.3rem;
  padding: 0rem 0.5rem;
  font-family: inherit;
  font-size: 14px;
`

const TableHeadingTextWrapper = styled.div<{ hasPointer: boolean }>`
  width: 100%;
  cursor: ${(props) => (props.hasPointer ? 'pointer' : 'default')};
`

function Filter<T>({
  column,
}: { column: Column<T, unknown>, }) {
  return (
    <Input
      type="text"
      value={(column.getFilterValue() ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder="Search..."
    />
  )
}

const FilterPlaceholder = styled.div`
  background-color: transparent;
  margin-top: 1rem;
  border-radius: 0.5rem;
  height: 1.8rem;
  padding: 0rem 0.5rem;
  min-height: 1.8rem;
`

const StyledNav = styled.nav`
  padding: var(--bespin-lib-measure-20) 0;
  display: flex;
  align-items: center;
  gap: 8px;
  .title {
    margin: 0;
  }
  button {
    padding: 8px;
  }
`

interface Props<T> {
  columns: ColumnDef<T>[],
  data: T[]
}

function ListingTable<T>({ columns, data }: Props<T>) {
  const memoizedColumns = useMemo(() => columns, [columns])
  const memoizedData = useMemo(() => data, [data])

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  })

  const table = useReactTable({
    columns: memoizedColumns,
    data: memoizedData,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  })

  return (
    <TableContainer>
      <TableWrapper>
        <BespinTable>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const { meta } = header.column.columnDef
                  return (
                    <th
                      style={meta?.width ? { width: meta.width } : undefined}
                      key={header.id}
                      title={typeof header.column.columnDef.header === 'string' ? header.column.columnDef.header : undefined}
                    >
                      <TableHeadingTextWrapper
                        hasPointer={header.column.getCanSort()}
                        {...{
                          onClick:
                            header.column.getCanSort()
                              ? header.column.getToggleSortingHandler() : undefined,
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        <span style={{ marginLeft: 'var(--bespin-lib-measure-4)' }}>
                          {{
                            asc: <BiCaretUp />,
                            desc: <BiCaretDown />,
                          }[header.column.getIsSorted() as string] ?? null}
                        </span>
                      </TableHeadingTextWrapper>
                      {header.column
                        .getCanFilter()
                        ? <Filter column={header.column} /> : <FilterPlaceholder aria-label="hidden" />}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} title={typeof cell.getValue() === 'string' ? cell.getValue() as string : undefined}>
                    {flexRender(
                      cell.column.columnDef.cell,
                      cell.getContext(),
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </BespinTable>

      </TableWrapper>
      <StyledNav>
        <ButtonGroup>
          <Button
            icon={<Icon icon={<BiChevronsLeft />} />}
            onClick={() => table.setPageIndex(0)}
            isDisabled={!table.getCanPreviousPage()}
            label=""
          />
          <Button
            icon={<Icon icon={<BiChevronLeft />} />}
            onClick={() => table.previousPage()}
            isDisabled={!table.getCanPreviousPage()}
            label=""
          />
        </ButtonGroup>
        <div className="title is-5">
          {table.getState().pagination.pageIndex + 1}
          /
          {table.getPageCount()}
        </div>
        <ButtonGroup>
          <Button
            icon={<Icon icon={<BiChevronRight />} />}
            onClick={() => table.nextPage()}
            isDisabled={!table.getCanNextPage()}
            label=""
          />
          <Button
            icon={<Icon icon={<BiChevronsRight />} />}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            isDisabled={!table.getCanNextPage()}
            label=""
          />
        </ButtonGroup>
      </StyledNav>
    </TableContainer>
  )
}

export default ListingTable
