import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { ColumnDef } from '@tanstack/react-table'
import { useNavigate } from 'react-router-dom'
import { Button, Icon } from '@bespin-ui/react-ui-components'
import { BiPlus } from 'react-icons/bi'
import { Table, ActionButtons } from '../../components'
import AppLayout from '../../layouts/AppLayout'
import { IDatabase } from '../../types/databases'
import { Columns } from '../../components/bulma'

import mongoClusters from './mockedMongo'
import { useAuth } from '../../providers/AuthProvider'
import { useFetchIsAdmin } from '../../hooks/api/admin'
import { ROUTES } from '../../constants'

const IconSpace = styled.span`
    padding-right: 10px;
`
const RightIcons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;  
`

function ListClusters() {
  const navigation = useNavigate()

  const { isAuthenticated } = useAuth()
  const { isLoading } = useFetchIsAdmin({ enabled: !isAuthenticated })

  const tableRowData = useMemo(() => [...mongoClusters], [])

  const columns: ColumnDef<IDatabase>[] = useMemo(() => [
    {
      header: 'Created By',
      accessorKey: 'createdBy',
      id: 'createdBy',
      meta: {
        width: '130px',
      },
    },
    {
      header: 'Instance Name',
      accessorKey: 'databaseName',
      id: 'databaseName',
    },
    {
      header: 'Provider',
      accessorKey: 'providerName',
      id: 'providerName',
      meta: {
        width: '130px',
      },
    },
    {
      header: 'Admin User Name',
      accessorKey: 'adminUsername',
      id: 'adminUsername',
    },
    {
      header: 'Data Center',
      accessorKey: 'datacenterName',
      id: 'datacenterName',
      meta: {
        width: '130px',
      },
    },
    {
      header: 'State',
      cell: ({ row }) => (row.original.state ? 'Active' : 'Inactive'),
      accessorKey: 'state',
      id: 'state',
      meta: {
        width: '100px',
      },
    },
    {
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => ActionButtons(
        {
          ...{
            viewCallback: () => {
              navigation(`${ROUTES.listDatabases}/${row.original._id}/view`, {
                state: {
                  mongoDBData: row.original,
                },
              })
            },
            disabledViewButton: false,
            editCallback: () => { /* */ },
            disabledEditButton: true,
            deleteCallback: () => { /* */ },
            disabledDeleteButton: true,
          },
        },
      ),
      meta: {
        width: '190px',
      },
    },
  ], [navigation])

  return (
    <AppLayout isLoading={isLoading}>
      <Columns>
        <Columns.Column
          desktop={{
            size: 6,
          }}
          mobile={{
            size: 12,
          }}
        >
          <h1 className="title is-4">
            <IconSpace>
              <FontAwesomeIcon icon={faDatabase} />
            </IconSpace>
            Clusters
          </h1>
        </Columns.Column>

        <Columns.Column
          desktop={{
            textAlign: 'right',
          }}
          mobile={{
            size: 12,
            textAlign: 'justify',
          }}
        >
          <RightIcons>
            <Button
              onClick={() => {
                navigation(ROUTES.createMongoDBCluster)
              }}
              size="small"
              label="Create Cluster"
              icon={<Icon icon={<BiPlus />} />}
              variant="primary"
            />
          </RightIcons>
        </Columns.Column>
      </Columns>
      <Table columns={columns} data={tableRowData} />
    </AppLayout>
  )
}
export default ListClusters
