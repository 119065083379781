import { useMutation, useQueryClient } from 'react-query'
import { useDBaaSClient } from '../../client'

interface ICreateDatabaseBody {
  dataCenter: string,
  providerName: string,
  serviceOffering: string,
  engine: string,
  engineVersion: string,
  identifier: string,
  dbName: string,
  storageSize: number,
  adminUsername: string,
  adminPassword: string,
  optionTimezoneZone: string,
  license: string,
  minorVersionUpgrade: boolean,
  majorVersionUpgrade: boolean,
  backupRetention: number,
  backupWindow: string,
  maintenanceWindow: string,
  multiAz: boolean,
  taxonomyId: number,
}

const useCreateAWSOracle = () => {
  const { post } = useDBaaSClient()

  const queryClient = useQueryClient()

  return useMutation((body?: Partial<ICreateDatabaseBody>) => post('/databases/tf', body), {
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries('databases')
    },
  })
}

export default useCreateAWSOracle
