import React from 'react'

interface ICredentialContext {
    accessToken?: string,
    uid?: string,
    email?: string
    name?: string
}

const CredentialContext = React.createContext<ICredentialContext>({
  accessToken: undefined,
  uid: undefined,
  email: undefined,
  name: undefined,
})

export default CredentialContext
