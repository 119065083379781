import { useQuery } from 'react-query'
import { useAuth } from '../../../providers/AuthProvider'
import IAdministrator from '../../../types/admin'
import { useDBaaSClient } from '../../client'

const useFetchAdmins = () => {
  const { get } = useDBaaSClient()
  const { isAuthenticated } = useAuth()

  return useQuery(['admins'], () => get<IAdministrator[]>('admins'), { enabled: isAuthenticated })
}

export default useFetchAdmins
