import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDBaaSClient } from '../../client'
import { useFetchIsAdmin } from '../admin'

interface IOrganization {
  id : string,
  atlasOrgId : string,
  name : string,
  description : string,
  execContactEmail : string,
  techContactEmail : string,
  costCenter : string,
  slackChannel : string,
  serviceNowGroup : string,
  pagerDutyId : string,
  createdBy : string,
  createdDate : string,
}

interface ICreateOrganizationPayload {
  name: string,
  description: string,
  taxonomyId: string,
  execContactEmail: string,
  techContactEmail: string,
  costCenter: string,
  slackChannel: string,
  serviceNowGroup: string,
  pagerDutyId: string,
  requestor: string,
  atlasOrgUsers: {
      username?: string,
      email?: string,
      roles?: string[],
  }[],
}

const useFetchOrganizations = () => {
  const { get } = useDBaaSClient()

  const { isFetching: isLoading, isSuccess } = useFetchIsAdmin()
  return useQuery(['organizations'], () => get<IOrganization[]>('/organizations'), {
    enabled: !isLoading && isSuccess,
  })
}

const useCreateOrganization = () => {
  const { post } = useDBaaSClient()

  const queryClient = useQueryClient()

  return useMutation((body: ICreateOrganizationPayload) => post<ICreateOrganizationPayload>('/organization', body), {
    onSuccess: () => {
      queryClient.invalidateQueries('organizations')
    },
  })
}

export { useCreateOrganization, useFetchOrganizations }
export type { ICreateOrganizationPayload, IOrganization }
