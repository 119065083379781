import React from 'react'
import styled from 'styled-components'
import { Button, ButtonGroup } from '@bespin-ui/react-ui-components'

const CustomActionButtonWrapper = styled.div`
div {
  gap: 0.4em;
}
div > button {
  padding: 0px 0.4rem;
}
`
export default function ActionButtons({
  viewCallback,
  disabledViewButton,
  editCallback,
  disabledEditButton,
  deleteCallback,
  disabledDeleteButton,
}:
  {
    viewCallback?: () => void,
    disabledViewButton?: boolean,
    editCallback?: () => void,
    disabledEditButton?: boolean,
    deleteCallback?: () => void,
    disabledDeleteButton?: boolean,
  }) {
  const ViewButton = viewCallback ? (
    <Button
      isDisabled={disabledViewButton}
      size="small"
      label="View"
      onClick={viewCallback}
      variant="primary"
    />
  ) : ''
  const EditButton = editCallback ? (
    <Button
      isDisabled={disabledEditButton}
      size="small"
      label="Edit"
      onClick={editCallback}
      variant="secondary"
    />
  ) : ''
  const DeleteButton = deleteCallback ? (
    <Button
      isDisabled={disabledDeleteButton}
      size="small"
      label="Delete"
      onClick={deleteCallback}
      isDanger
      variant="primary"
    />
  ) : ''
  return (
    <CustomActionButtonWrapper>
      <ButtonGroup>
        {ViewButton}
        {EditButton}
        {DeleteButton}
      </ButtonGroup>
    </CustomActionButtonWrapper>
  )
}

ActionButtons.defaultProps = {
  viewCallback: undefined,
  editCallback: undefined,
  deleteCallback: undefined,
  disabledViewButton: false,
  disabledEditButton: false,
  disabledDeleteButton: false,
}
