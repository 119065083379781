import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import {
  createSearchParams, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom'
import moment from 'moment'
import { Tabs } from '@bespin-ui/react-ui-components'
import {
  Button as BulmaButton, Columns, Container, Form,
} from '../../components/bulma'
import {
  Panel, PanelBody,
} from '../../components'
import AppLayout from '../../layouts'
import { TITLES } from '../../constants'
import { useFetchIsAdmin } from '../../hooks/api/admin'
import useMaintenanceStatus from '../../hooks/maintenance'

import useEnvironment from '../../hooks/env'
import {
  AdministrationConsumer,
  AdministrationProvider,
  TabCloudAccounts,
  TabUserAccounts,
  TabServers,
} from '../../modules/Administration'

interface ITabs {
  'User Accounts': typeof TabUserAccounts,
  'Servers'?: typeof TabServers
  'Cloud Accounts'?: typeof TabCloudAccounts
 }

function ListAdministrator() {
  useFetchIsAdmin()
  const env = useEnvironment()
  const [searchParams] = useSearchParams()

  const tabs: ITabs = useMemo(() => {
    const isDev = env === 'dev'
    let devTabs = {}
    if (isDev) {
      devTabs = {
        // 'Cloud Accounts': TabCloudAccounts,
      }
    }
    return ({
      'User Accounts': TabUserAccounts,
      // Servers: TabServers,
      ...devTabs,
    })
  }, [env])

  const selectedTab = useMemo(() => (searchParams.get('tab') ?? 'User Accounts') as keyof ITabs, [searchParams])

  const navigate = useNavigate()
  const { pathname } = useLocation()

  React.useEffect(() => {
    if (!searchParams.get('tab')) {
      navigate({
        pathname,
        search: createSearchParams({ tab: 'User Accounts' }).toString(),
      }, { replace: true })
    }
  }, [navigate, pathname, searchParams, tabs])

  const [splashFormEnabled, setSplashFormEnabled] = useState(false)
  const [customSplashText, setCustomSplashText] = useState('')

  const { setMaintenanceStatus } = useMaintenanceStatus()

  const TabContentComponent = tabs[selectedTab]

  return (
    <>
      <Helmet>
        <title>{TITLES.listAdministrators}</title>
      </Helmet>
      <AdministrationProvider>
        <AdministrationConsumer>
          {({ isLoading }) => (
            <AppLayout isLoading={isLoading}>
              <Container className="is-fluid pr-0 pl-0">
                <Columns multiline={false}>
                  <Columns.Column>
                    <h4 className="title is-4">
                      <FontAwesomeIcon className="pr-3" icon={faDatabase} />
                      Administration
                    </h4>
                  </Columns.Column>
                </Columns>
                {env === 'unknown'
                  && (
                    <Columns multiline>
                      <Columns.Column size={12}>
                        <Panel title="Admin Settings">
                          <PanelBody>
                            <Columns>
                              <Columns.Column className="is-flex is-flex-direction-row-reverse">
                                <Form.Field>
                                  <Form.Switch
                                    name="Enable splash page"
                                    checked={splashFormEnabled}
                                    onChange={() => { setSplashFormEnabled(!splashFormEnabled) }}
                                  />
                                </Form.Field>
                              </Columns.Column>
                            </Columns>
                            {splashFormEnabled
                              && (
                                <>
                                  <Form.Field horizontal pt={3}>
                                    <Form.Field.Label size="normal">
                                      <Form.Label>
                                        Custom Splash Page Message*
                                      </Form.Label>
                                    </Form.Field.Label>
                                    <Form.Field.Body>
                                      <Form.Field>
                                        <Form.Control fullwidth>
                                          <Form.TextArea
                                            value={customSplashText}
                                            onChange={(e:
                                              React.ChangeEvent<HTMLTextAreaElement>) => {
                                              setCustomSplashText(e.target.value)
                                            }}
                                            placeholder="DBaaS is under maintenance..."
                                          />
                                        </Form.Control>
                                      </Form.Field>
                                    </Form.Field.Body>
                                  </Form.Field>
                                  <Form.Field display="flex" justifyContent="flex-end">
                                    <Form.Control>
                                      <BulmaButton
                                        color="success"
                                        disabled={!customSplashText.length}
                                        type="button"
                                        onClick={() => {
                                          localStorage.setItem('isByPassEnabled', 'false')
                                          localStorage.setItem(
                                            'maintenance',
                                            JSON.stringify({
                                              message: customSplashText,
                                              date: moment().add(1, 'h').toDate().toString(),
                                            }),
                                          )
                                          setMaintenanceStatus({
                                            isLoading: false,
                                            isUnderMaintenance: true,
                                          })
                                          setCustomSplashText('')
                                          setSplashFormEnabled(false)
                                        }}
                                      >
                                        Save
                                      </BulmaButton>
                                    </Form.Control>
                                  </Form.Field>
                                </>
                              )}
                          </PanelBody>
                        </Panel>
                      </Columns.Column>
                    </Columns>
                  )}
                <Columns>
                  <Columns.Column className="pt-6" size={12}>
                    <Tabs>
                      {Object.keys(tabs).map((option) => (
                        <Tabs.Tab
                          key={option}
                          label={option}
                          onClick={() => {
                            navigate({
                              pathname,
                              search: createSearchParams({ tab: option }).toString(),
                            })
                          }}
                          selected={selectedTab === option}
                        />
                      ))}
                    </Tabs>
                  </Columns.Column>
                  <Columns.Column>
                    {TabContentComponent && <TabContentComponent />}
                  </Columns.Column>
                </Columns>
              </Container>
            </AppLayout>
          )}
        </AdministrationConsumer>
      </AdministrationProvider>
    </>
  )
}

export default ListAdministrator
