const basePath = '/dbaasAdmin'
const listDatabases = `${basePath}/databases`
const editDatabaseById = `${listDatabases}/:databaseID/edit`
const viewDatabaseDetails = `${listDatabases}/:databaseID/view`
const createDatabase = `${basePath}/create`
const createDatabaseEdit = `${createDatabase}/edit`
const databaseCreatedConfirm = `${basePath}/create-form-summary`
const listServers = `${basePath}/servers`
const editServerById = `${listServers}/:serverID/edit`
const viewServer = `${listServers}/:serviceID/view`
const createServerConfigure = `${listServers}/createServer`
const listAdministrator = `${basePath}/administration`
const editAdministrator = `${listAdministrator}/:adminId/edit`
const createAdministrator = `${basePath}/createAdministrator`
const createCloudAccount = `${basePath}/createCloudAccount`
const listEvents = `${basePath}/events`
const viewEventDetails = `${basePath}/events/:eventId/view`
const listLogs = `${basePath}/listLog`
const viewLogDetails = `${basePath}/viewLog`
const listOrganization = `${basePath}/organizations`
const viewOrganizationDetails = `${listOrganization}/:orgId/view`
const listClusters = `${basePath}/clusters`
const createOrganization = `${basePath}/createOrganization`
const createMongoDBCluster = `${basePath}/create-mongodb-cluster`

const ROUTES = {
  basePath,
  listDatabases,
  createDatabase,
  createDatabaseEdit,
  editDatabaseById,
  databaseCreatedConfirm,
  editAdministrator,
  listServers,
  editServerById,
  viewServer,
  viewDatabaseDetails,
  viewOrganizationDetails,
  createServerConfigure,
  listAdministrator,
  createAdministrator,
  createCloudAccount,
  listEvents,
  viewEventDetails,
  listLogs,
  viewLogDetails,
  listClusters,
  listOrganization,
  createOrganization,
  createMongoDBCluster,
}

export default ROUTES
