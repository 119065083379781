/* eslint-disable @typescript-eslint/no-unused-vars */
import { faCaretLeft, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { DetailsTable } from '../../components'
import { ROUTES, TITLES } from '../../constants'
import AppLayout from '../../layouts'
import { useFetchIsAdmin } from '../../hooks/api/admin'
import { useAuth } from '../../providers/AuthProvider'
import IServiceLog from '../../types/serviceLog'
import { Columns, Container } from '../../components/bulma'
import { IOrganization } from '../../hooks/api/organizations'

function OrgViewDetailsPage() {
  const { isAuthenticated } = useAuth()
  useFetchIsAdmin({ enabled: !isAuthenticated })
  const location = useLocation()

  const orgDetails = useMemo(() => location.state as IOrganization | undefined, [location])

  const data = useMemo(() => {
    const details = [
      { name: 'Organization name', value: orgDetails?.name ?? '' },
      { name: 'Description', value: orgDetails?.description ?? '' },
      { name: 'Executive Email', value: orgDetails?.execContactEmail ?? '' },
      { name: 'Tech Email', value: orgDetails?.techContactEmail ?? '' },
      { name: 'Cost Center', value: orgDetails?.costCenter ?? '' },
      { name: 'Slack Channel', value: orgDetails?.slackChannel ?? '' },
      { name: 'Service Now Group', value: orgDetails?.serviceNowGroup ?? '' },
      { name: 'Pager Duty Id', value: orgDetails?.pagerDutyId ?? '' },
      { name: 'Created By', value: orgDetails?.createdBy ?? '' },
      { name: 'Created Date', value: orgDetails?.createdDate ?? '' },
    ]
    return details
  }, [orgDetails])

  return (
    <AppLayout>
      <Container className="is-fluid">
        <Columns multiline={false} className="mt-5">
          <Columns.Column>
            <h4 className="title is-4">
              <Link to={ROUTES.listOrganization} replace>
                <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
              </Link>
              <FontAwesomeIcon className="pr-3" icon={faDatabase} />
              Organizations &gt; View
              {' '}
            </h4>

          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <DetailsTable data={data} />
          </Columns.Column>
        </Columns>
      </Container>
    </AppLayout>
  )
}

export default OrgViewDetailsPage
