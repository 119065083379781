import { useQuery } from 'react-query'
import { IDatabase, IOracleDatabase } from '../../../types/databases'
import { useDBaaSClient } from '../../client'
import { useFetchIsAdmin } from '../admin'

type TypeDatabase = Partial<IDatabase & IOracleDatabase>

const useFetchDatabaseById = ({ databaseID, isOracle }:
  { databaseID?: string, isOracle: boolean }) => {
  const { get } = useDBaaSClient()
  const { isFetching: isLoading, isSuccess } = useFetchIsAdmin({ enabled: databaseID != null })
  const canFetch = !isLoading && isSuccess

  const query = useQuery([isOracle ? 'tfdatabases' : 'databases', databaseID], () => get<TypeDatabase>(`/${isOracle ? 'tfdatabases' : 'databases'}/${databaseID}`), {
    enabled: canFetch && databaseID != null,
    retry: false,
  })

  const isFetching = isLoading || query.isFetching

  return { ...query, isFetching }
}

export default useFetchDatabaseById
