// Used for page titles (analytics)
const prefix = 'DBaaS -'
const createDatabase = `${prefix} Create Database`
const createDatabaseConfirm = `${prefix} Create Database Confirm`

const createAdministrator = `${prefix} Create Administrator`
const createCloudAccount = `${prefix} Create Cloud Account`
const listAdministrators = `${prefix} List Administrator`
const createServerConfiguration = `${prefix} Create Server Configuration`
const editAdministrator = `${prefix} Edit Administrator`
const editDatabase = `${prefix} Edit Database`
const editServerById = `${prefix} Edit Server By ID`
const home = `${prefix} Home`
const listDBaaS = `${prefix} List DBaaS`
const listEvents = `${prefix} List Events`
const listServer = `${prefix} List Servers`
const listServiceLogs = `${prefix} List Service Logs`
const serverDetailsView = `${prefix} Server Details`
const viewDatabaseDetails = `${prefix} View Database Details`
const viewEventDetails = `${prefix} View Event Details`
const viewServiceLogDetails = `${prefix} View Service Log Details`

const TITLES = {
  createDatabase,
  createCloudAccount,
  createDatabaseConfirm,
  createAdministrator,
  listAdministrators,
  createServerConfiguration,
  editAdministrator,
  editDatabase,
  editServerById,
  home,
  listDBaaS,
  listEvents,
  listServer,
  listServiceLogs,
  serverDetailsView,
  viewDatabaseDetails,
  viewEventDetails,
  viewServiceLogDetails,
}

export default TITLES
