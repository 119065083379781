import { faCaretLeft, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo } from 'react'
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import {
  DetailsTable, ErrorBannerNotification, NodesTable, Panel,
  PanelBody,
  Spinner,
} from '../../components'
import { ROUTES, TITLES } from '../../constants'
import { useFetchDatabaseById, useFetchDatabaseStats } from '../../hooks/api/databases'
import { useFetchTaxonomyDetailsById } from '../../hooks/api/taxonomy'
import { Columns, Container } from '../../components/bulma'
import { IDatabase, IOracleDatabase } from '../../types/databases/index'

const HighchartsReactContainer = styled.div`
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 14px 14px;
    background: white;
    border-radius: 3px;
    border: 1px solid #eeeeee;
`

interface INavigationParams {
  databaseID: string
}

function ViewDatabaseDetails() {
  const { databaseID } = useParams<keyof INavigationParams>()

  // Refactor Oracle below code when api is ready
  const location = useLocation()

  const navigate = useNavigate()

  useEffect(() => {
    if (!(location.search === '?oracle=true' || location.search === '')) {
      navigate(ROUTES.listDatabases, { replace: true })
    }
  }, [location.search, navigate])

  const isOracle = useMemo(
    () => location.search === '?oracle=true',
    [location.search],
  )

  const isMongoDbMockUiData = location.state?.mongoDBData != null

  const mongoDbMockUiData = [
    { name: 'Organization', value: 'Disneyverse (mock)' },
    { name: 'Project', value: 'Stream Disney (mock)' },
    { name: 'Instance Size', value: 'M10 (mock)' },
    { name: 'Ram', value: '8 GB (mock)' },
    { name: 'vCPU', value: '2 (mock)' },
    { name: 'Disk Size', value: '20 GB (mock)' },
    { name: 'Cluster Region', value: 'us-west-2 (mock)' },
    { name: 'Cluster Name', value: 'Cluster0' },
    { name: 'Electable Nodes', value: '3 (mock)' },
    { name: 'ReadOnly Nodes', value: '3 (mock)' },
    { name: 'MongoDB Version', value: '5 (mock)' },
    { name: 'Backup', value: 'Active (mock)' },
    { name: 'Private Endpoint', value: 'Active (mock)' },
  ]

  const {
    data: databaseResponse,
    isFetching: isDatabaseLoading, isError: isDatabaseError,
  } = useFetchDatabaseById({ databaseID: isMongoDbMockUiData ? undefined : databaseID, isOracle })

  const database = databaseResponse?.data

  const {
    data: taxonomyDetailsResponse,
    isFetching: isTaxonomyLoading, isError: isTaxonomyError,
  } = useFetchTaxonomyDetailsById(database?.taxonomyId, { enabled: database != null })

  const taxonomyData = taxonomyDetailsResponse?.data

  const {
    data: databaseStatsResponse,
    isFetching: isDatabaseStatsLoading, isError: isDatabaseStatsError,
  } = useFetchDatabaseStats(database?._id, { enabled: database != null && !isOracle })

  const databaseStats = databaseStatsResponse?.data

  const isLoading = isDatabaseLoading || isTaxonomyLoading || isDatabaseStatsLoading

  const seriesData = useMemo(() => {
    const data: Array<(string | number)[]> = []
    databaseStats?.forEach((state) => {
      data.push([state.timestamp, Number(state.total_size_mb)])
    })
    return data
  }, [databaseStats])

  const data = useMemo(() => {
    if (isOracle) {
      const oracleDatabase = database as Partial<IOracleDatabase>
      const oracleDetails = [
        { name: 'ID', value: oracleDatabase?._id ?? '' },
        { name: 'Database Name', value: oracleDatabase?.databaseName ?? '' },
        { name: 'Datacenter Name', value: oracleDatabase?.datacenterName ?? '' },
        { name: 'Admin Username', value: oracleDatabase?.adminUsername ?? '' },
        { name: 'Size', value: (oracleDatabase?.size != null) ? oracleDatabase.size.toString() : '' },
        { name: 'Provider', value: oracleDatabase?.providerName ?? '' },
        { name: 'Taxonomy ID', value: oracleDatabase?.taxonomyId ?? '' },
        { name: 'Segment', value: taxonomyData?.businessUnitGroupName ?? '' },
        { name: 'Business Unit', value: taxonomyData?.businessName ?? '' },
        { name: 'Affiliate Name', value: taxonomyData?.affiliateName ?? '' },
        // { name: 'Engine', value: 'oracle-se2 (mock)' },
        { name: 'Identifier', value: oracleDatabase?.identifier ?? '' },
        { name: 'Region', value: oracleDatabase?.region ?? '' },
        { name: 'License', value: oracleDatabase?.license ?? '' },
        { name: 'Minor Version Upgrade', value: oracleDatabase?.minorVersionUpgrade === 'true' ? 'Yes' : 'No' },
        { name: 'Major Version Upgrade', value: oracleDatabase?.majorVersionUpgrade === 'true' ? 'Yes' : 'No' },
        { name: 'Backup Retention', value: oracleDatabase?.backupRetention ?? '' },
        { name: 'Backup Window', value: oracleDatabase?.backupWindow ?? '' },
        { name: 'Maintenance Window', value: oracleDatabase?.maintenanceWindow ?? '' },
        { name: 'Multi-AZ', value: oracleDatabase?.multiAz === 'true' ? 'Yes' : 'False' },
        { name: 'State', value: oracleDatabase?.state ? 'Active' : 'Inactive' },
        { name: 'Created At', value: oracleDatabase?.createdAt ?? '' },
        { name: 'Created By', value: oracleDatabase?.createdBy ?? '' },
      ]
      return oracleDetails
    }
    const defaultDatabase = database as Partial<IDatabase>
    const details = [
      { name: 'ID', value: defaultDatabase?._id ?? '' },
      { name: 'Provider Name', value: defaultDatabase?.providerName ?? '' },
      { name: 'Database Name', value: defaultDatabase?.databaseName ?? '' },
      { name: 'Size', value: (defaultDatabase?.size != null) ? defaultDatabase.size.toString() : '' },
      { name: 'Connection String', value: defaultDatabase?.connectionString ?? '' },
      { name: 'Created By', value: defaultDatabase?.createdBy ?? '' },
      { name: 'Owners', value: defaultDatabase?.owners ? defaultDatabase?.owners.join(' ') : '' },
      { name: 'Server ID', value: defaultDatabase?.serverId ?? '' },
      { name: 'Taxonomy ID', value: defaultDatabase?.taxonomyId ?? '' },
      { name: 'Segment', value: taxonomyData?.businessUnitGroupName ?? '' },
      { name: 'Business Unit', value: taxonomyData?.businessName ?? '' },
      { name: 'Affiliate Name', value: taxonomyData?.affiliateName ?? '' },
      { name: 'Admin Username', value: defaultDatabase?.adminUsername?.toUpperCase() ?? '' },
      { name: 'Created At', value: defaultDatabase?.createdAt ?? '' },
      { name: 'Updated At', value: defaultDatabase?.updatedAt ?? '' },
    ]
    return details
  }, [database, taxonomyData, isOracle])

  const options: Highcharts.Options = useMemo(() => ({
    title: {
      text: 'Usage History',
      align: 'left',
    },
    chart: {
      renderTo: 'container',
      zoomType: 'xy',
      reflow: true,
      type: 'line',
      events: {},
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      type: 'datetime',
      labels: {
        type: 'datetime',
        formatter: (obj: Highcharts.AxisLabelsFormatterContextObject) => {
          const d = new Date(seriesData[Number(obj.value)][0])
          return `${d.getMonth() + 1}-${d.getDate()}-${d.getFullYear()}`
        },
      },
    },
    yAxis: {
      labels: {
        format: '{value:.0f}',
      },
      title: {
        text: 'MB',
      },
    },
    legend: {
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
    },
    accessibility: { enabled: false },
    series: [{
      name: 'Storage Usage',
      data: seriesData,
      type: 'line',
    }],
  }), [seriesData])

  const getErrorNotification = useMemo(() => {
    if (isDatabaseError) {
      return (
        <ErrorBannerNotification
          message="Failed to fetch Database information. Please contact DBaaS support."
        />
      )
    }
    if (isDatabaseStatsError) {
      return (
        <ErrorBannerNotification
          message="Failed to fetch Database stats. Please contact DBaaS support."
        />
      )
    }
    if (isTaxonomyError) {
      return (
        <ErrorBannerNotification
          message="Failed to fetch Taxonomy details. Please contact DBaaS support."
        />
      )
    }
    return ''
  }, [isDatabaseError, isDatabaseStatsError, isTaxonomyError])

  const databaseOrClusterNameText = useMemo(() => {
    if (isMongoDbMockUiData) {
      return 'Cluster0 : '
    }
    return database?.databaseName != null ? `${database?.databaseName} : ` : ''
  }, [database?.databaseName, isMongoDbMockUiData])

  return (
    <>
      <Helmet>
        <title>{TITLES.viewDatabaseDetails}</title>
      </Helmet>
      <Spinner isLoading={isLoading} isFullScreen>
        <Container>
          <Columns multiline className="pt-5">
            <Columns.Column size={12}>
              <h4 className="title is-4">
                <Link to={isMongoDbMockUiData ? ROUTES.listClusters : ROUTES.listDatabases} replace>
                  <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
                </Link>
                <FontAwesomeIcon className="pr-3" icon={faDatabase} />
                Databases &gt; View
                {' '}
                {databaseOrClusterNameText}
              </h4>
              {getErrorNotification}
            </Columns.Column>
          </Columns>
          <Columns flexDirection="column" multiline={false}>
            <Columns.Column>
              {(isMongoDbMockUiData)
                ? (
                  <DetailsTable data={mongoDbMockUiData} />
                )
                : <DetailsTable data={data} />}
            </Columns.Column>
            {isMongoDbMockUiData && (
              <Columns.Column>
                <Panel title="Nodes" titleContainerClassName="px-2 py-1" titleClassName="" className="p-0">
                  <PanelBody>
                    <Panel title="Region us-west-2" titleContainerClassName="px-2 py-1" titleClassName="">
                      <NodesTable data={[{
                        status: 'Active',
                        value: 'cluster0-shard-00-00.twnx.mongodb.net:27017',
                        type: 'Primary',
                      }, {
                        status: 'Active',
                        value: 'cluster0-shard-00-02.twnx.mongodb.net:27017',
                        type: 'Secondary',
                      },
                      {
                        status: 'Active',
                        value: 'cluster0-shard-00-03.twnx.mongodb.net:27017',
                        type: 'Secondary',
                      }]}
                      />
                    </Panel>
                  </PanelBody>
                </Panel>
              </Columns.Column>
            )}

            <Columns.Column>
              {(!isMongoDbMockUiData && !isOracle)
                && (
                  <HighchartsReactContainer>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={options}
                    />
                  </HighchartsReactContainer>
                )}
            </Columns.Column>
          </Columns>
        </Container>
      </Spinner>
    </>
  )
}

export default ViewDatabaseDetails
