import React, { useContext, useEffect, useRef } from 'react'

const AdministrationContext = React.createContext<{
    isLoading: boolean,
    updateLoadingState:(state: boolean) => void
      }>({
        isLoading: false,
        updateLoadingState: () => {
        //
        },
      })

function AdministrationProvider({ children }: { children: React.ReactNode }) {
  const [isLoading, setIsLoading] = React.useState(false)
  return (
    <AdministrationContext.Provider value={{ isLoading, updateLoadingState: setIsLoading }}>
      {children}
    </AdministrationContext.Provider>
  )
}

export const useSyncLoadingStatus = (state: boolean) => {
  const { updateLoadingState } = useContext(AdministrationContext)
  const updateLoadingStateRef = useRef(updateLoadingState)
  useEffect(() => {
    updateLoadingStateRef.current(state)
  }, [state])
}

export const useIsLoading = () => {
  const { isLoading } = useContext(AdministrationContext)
  return isLoading
}

export default AdministrationProvider

export const AdministrationConsumer = AdministrationContext.Consumer
