import React, { useMemo, useState } from 'react'
import * as bulmaToast from 'bulma-toast'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { BiEnvelope, BiPlus } from 'react-icons/bi'
import { Button, Icon } from '@bespin-ui/react-ui-components'
import { ColumnDef } from '@tanstack/react-table'
import {
  ConfirmModal, ErrorBannerNotification, Table, ActionButtons,
} from '../../components'
import AppLayout from '../../layouts/AppLayout'
import ROUTES from '../../constants/ROUTES'
import useFetchDatabases from '../../hooks/api/databases/useFetchDatabases'
import { useDeleteDatabaseById } from '../../hooks/api/databases'
import { IDatabase } from '../../types/databases'
import { TITLES } from '../../constants'
import { Columns } from '../../components/bulma'

const IconSpace = styled.span`
    padding-right: 10px;
`
const RightIcons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
`

interface IFormattedDatabase extends Omit<IDatabase, 'state'> {
  state: string
}

function ListDBaaS() {
  const navigation = useNavigate()
  const { pathname } = useLocation()

  const { data, isFetching: isDatabaseLoading } = useFetchDatabases()

  const [selectedDatabase, setSelectedDatabase] = useState<IDatabase | undefined>()

  const {
    mutate, isLoading: isDatabaseDeleteLoading, isError, reset, error,
  } = useDeleteDatabaseById()

  const isLoading = isDatabaseLoading || isDatabaseDeleteLoading

  const tableRowData = useMemo(() => data?.data.map((entry) => ({
    ...entry,
    state: entry.state ? 'Active' : 'Inactive',
    createdBy: entry.createdBy ?? '',
    databaseName: entry.databaseName ?? '',
    providerName: entry.providerName ?? '',
    adminUsername: entry.adminUsername ?? '',
    datacenterName: entry.datacenterName ?? '',
  })) ?? [], [data?.data])

  const columns: ColumnDef<IFormattedDatabase>[] = useMemo(() => [
    {
      header: 'Created By',
      accessorKey: 'createdBy',
      id: 'createdBy',
      meta: {
        width: '130px',
      },
    },
    {
      header: 'Instance Name',
      accessorKey: 'databaseName',
      id: 'databaseName',
    },
    {
      header: 'Provider',
      accessorKey: 'providerName',
      id: 'providerName',
      meta: {
        width: '130px',
      },
    },
    {
      header: 'Admin User Name',
      accessorKey: 'adminUsername',
      id: 'adminUsername',
    },
    {
      header: 'Data Center',
      accessorKey: 'datacenterName',
      id: 'datacenterName',
      meta: {
        width: '130px',
      },
    },
    {
      header: 'State',
      filterFn: 'arrIncludes',
      accessorKey: 'state',
      id: 'state',
      meta: {
        width: '100px',
      },
    },
    {
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => {
        const isOracle = (row.original.providerName == null
          || row.original.providerName.trim().length < 1
          || row.original.providerName.toLocaleLowerCase().includes('oracle'))

        return ActionButtons(
          {
            ...{
              viewCallback: () => {
                if (isOracle) {
                  navigation(`${pathname}/${row.original._id}/view?oracle=true`, {
                    state: {
                      oracleExistingRowData: row.original,
                    },
                  })
                  return
                }
                navigation(`${pathname}/${row.original._id}/view`)
              },
              editCallback: () => { navigation(`${pathname}/${row.original._id}/edit`) },
              disabledEditButton: isOracle,
              deleteCallback: () => {
                setSelectedDatabase(data?.data.find((value) => value._id === row.original._id))
              },
              disabledDeleteButton: isOracle,
            },
          },
        )
      },
      meta: {
        width: '190px',
      },
    },
  ], [navigation, pathname, data?.data])

  const closeModal = ():void => setSelectedDatabase(undefined)

  return (
    <>
      <Helmet>
        <title>{TITLES.listDBaaS}</title>
      </Helmet>
      <AppLayout isLoading={isLoading}>
        <ConfirmModal
          title={`Are you sure you want to delete ${selectedDatabase?.databaseName}`}
          description={`You are about to delete the database ${selectedDatabase?.databaseName}, you can not undo this action, are you sure you want to continue?`}
          isOpen={selectedDatabase != null}
          handleClose={closeModal}
          okayButton={(
            <Button
              variant="primary"
              isDanger
              onClick={() => {
                if (selectedDatabase != null) {
                  mutate(selectedDatabase, {
                    onSettled: closeModal,
                    onSuccess: () => {
                      bulmaToast.toast({
                        message: `<b>${selectedDatabase?.databaseName}</b> Database is successfully deleted`,
                        type: 'is-success',
                        dismissible: true,
                        duration: 6000,
                        position: 'bottom-right',
                        animate: { in: 'fadeIn', out: 'fadeOut' },
                      })
                    },
                  })
                } else {
                  closeModal()
                }
              }}
              isDisabled={isDatabaseDeleteLoading || selectedDatabase?.providerName.toLowerCase() === 'mongodb'}
              label="Delete"
            />
          )}
          cancelButton={(
            <Button
              isDisabled={isDatabaseDeleteLoading}
              label="Cancel"
              onClick={closeModal}
            />
          )}
        />
        <Columns>
          <Columns.Column
            desktop={{
              size: 6,
            }}
            mobile={{
              size: 12,
            }}
          >
            <h1 className="title is-4">
              <IconSpace>
                <FontAwesomeIcon icon={faDatabase} />
              </IconSpace>
              On Demand Databases
            </h1>
          </Columns.Column>

          <Columns.Column
            desktop={{
              textAlign: 'right',
            }}
            mobile={{
              size: 12,
              textAlign: 'justify',
            }}
          >
            <RightIcons>
              <Button
                onClick={() => { window.location.href = 'mailto:DTSS.DBAAS.Program.Team@disney.com' }}
                size="small"
                variant="secondary"
                label="Feedback"
                icon={<Icon icon={<BiEnvelope />} />}
              />
              <Button
                onClick={() => { navigation(ROUTES.createDatabase) }}
                size="small"
                label="Create Database"
                icon={<Icon icon={<BiPlus />} />}
                variant="primary"
              />
            </RightIcons>
          </Columns.Column>
          {isError && (
            <Columns.Column size={12}>
              <ErrorBannerNotification
                message={error?.message ?? ''}
                dismissButtonCallback={reset}
              />
            </Columns.Column>
          )}
        </Columns>
        <Table columns={columns} data={tableRowData} />
      </AppLayout>
    </>
  )
}
export default ListDBaaS
