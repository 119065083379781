import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as bulmaToast from 'bulma-toast'
import { Button } from '@bespin-ui/react-ui-components'
import { ColumnDef } from '@tanstack/react-table'
import { BiPlus } from 'react-icons/bi'
import { Columns } from '../../components/bulma'
import {
  ActionButtons,
  ConfirmModal, ErrorBannerNotification, Table,
} from '../../components'
import { ROUTES } from '../../constants'
import { useDeleteAdministratorById, useFetchAdmins } from '../../hooks/api/admin'
import IAdministrator from '../../types/admin'

import { useSyncLoadingStatus } from './AdministrationProvider'

function TabUserAccounts() {
  const navigate = useNavigate()

  const { data: adminsResponse, isFetching: isAdminsLoading } = useFetchAdmins()
  const admins = adminsResponse?.data ?? []

  const [selectedAdministrator, setSelectedAdministrator] = useState<IAdministrator | undefined>()

  const onClickCreateServerPage = () => {
    navigate(ROUTES.createAdministrator)
  }

  const {
    mutate, isLoading: isAdminDeleteLoading, isError, error, reset,
  } = useDeleteAdministratorById()

  const isLoading = isAdminsLoading || isAdminDeleteLoading

  useSyncLoadingStatus(isLoading)

  const columns: ColumnDef<IAdministrator>[] = React.useMemo(() => [
    {
      header: 'Name',
      accessorKey: 'name',
      id: 'name',
    },
    {
      header: 'Actions',
      id: 'actions',
      cell: ({ row }) => ActionButtons(
        {
          ...{
            editCallback: () => { navigate(`${ROUTES.listAdministrator}/${row.original._id}/edit`) },
            deleteCallback: () => { setSelectedAdministrator(row.original) },
          },
        },
      ),
      meta: {
        width: '140px',
      },
    },
  ], [navigate])

  const closeModal = ():void => setSelectedAdministrator(undefined)

  return (
    <>
      <ConfirmModal
        title={`Are you sure you want to delete ${selectedAdministrator?.name}`}
        description={`You are about to delete the Admin ${selectedAdministrator?.name}, you can not undo this action, are you sure you want to continue?`}
        isOpen={selectedAdministrator != null}
        handleClose={closeModal}
        okayButton={(
          <Button
            variant="primary"
            isDanger
            onClick={() => {
              if (selectedAdministrator != null) {
                mutate(selectedAdministrator?._id ?? '', {
                  onSettled: closeModal,
                  onSuccess: () => {
                    bulmaToast.toast({
                      message: `<b>${selectedAdministrator.name}</b> user is successfully deleted`,
                      type: 'is-success',
                      dismissible: true,
                      duration: 6000,
                      position: 'bottom-right',
                      animate: { in: 'fadeIn', out: 'fadeOut' },
                    })
                  },
                })
              } else {
                closeModal()
              }
            }}
            isDisabled={isAdminDeleteLoading}
            label="Delete"
          />
        )}
        cancelButton={(
          <Button
            isDisabled={isAdminDeleteLoading}
            onClick={() => { setSelectedAdministrator(undefined) }}
            label="Cancel"
          />
        )}
      />
      {isError
        && (
          <Columns>
            <Columns.Column size={12}>
              <ErrorBannerNotification
                message={error?.message ?? ''}
                dismissButtonCallback={reset}
              />
            </Columns.Column>
          </Columns>
        )}
      <Columns>
        <Columns.Column size={12} className="is-flex is-flex-direction-row-reverse">
          <Button
            onClick={onClickCreateServerPage}
            size="small"
            variant="primary"
            label="Create New Administrator"
            icon={<BiPlus />}
          />
        </Columns.Column>

        <Columns.Column>
          <Table
            columns={columns}
            data={admins}
          />
        </Columns.Column>
      </Columns>
    </>
  )
}

export default TabUserAccounts
