import React from 'react'
import { faCaretLeft, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createSearchParams, Link, useLocation } from 'react-router-dom'
import { Container } from '../../components/bulma'
import ROUTES from '../../constants/ROUTES'
import AppLayout from '../../layouts'
import { EditServerByIdConsumer, EditServerByIProvider } from './EditServerByIdContext'

function EditServerByIdLayout({ children }:{
  children?:JSX.Element | JSX.Element[] | string
}) {
  const location = useLocation()

  const canGoBack = React.useMemo(() => location.state != null && 'canGoBack' in location.state && location.state.canGoBack, [location.state])

  return (
    <EditServerByIProvider>
      <EditServerByIdConsumer>
        {(value) => (
          <AppLayout isLoading={value.isLoading}>
            <Container className="is-fluid pr-0 pl-0">
              <h4 className="title is-4">
                <Link
                  to={canGoBack
                    ? `${ROUTES.listAdministrator}?${createSearchParams({ tab: 'Servers' }).toString()}` : ROUTES.listAdministrator}
                  replace
                >
                  <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
                </Link>
                <FontAwesomeIcon className="pr-3" icon={faDatabase} />
                Servers &gt; Edit Server :
                {' '}
                {value.initialServerData?.name}
              </h4>
              <div>
                {children}
              </div>
            </Container>
          </AppLayout>
        )}
      </EditServerByIdConsumer>
    </EditServerByIProvider>
  )
}

EditServerByIdLayout.defaultProps = {
  children: undefined,
}

export default EditServerByIdLayout
