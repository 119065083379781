import React, { useEffect, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import ROUTES from '../../constants/ROUTES'
import { useFetchAffiliates, useFetchBusinessUnit, useFetchSegment } from '../../hooks/api/taxonomy'
import { IEditCreateDatabaseFormFields, ITaxonomy } from '../../types/createDatabase'
import Panel, { PanelBody } from '../Panel'
import { Form } from '../bulma'
import Label from '../Label'

interface Props {
  onLoading: (isLoading: boolean) => void;
  onSelected: (taxonomyId?: string) => void;
  initialTaxonomyId?: string;
  bottomContent?: React.ReactNode;
}

const schema = yup.object({
  segment: yup.string().required(),
  businessUnit: yup.string().required(),
  affiliate: yup.string().required(),
})

const taxonomySchema = yup.object({
  taxonomyId: yup.number().test('len', 'Must be 4 digit', (value) => value?.toString().length === 4),
})

function Taxonomy({
  onLoading, onSelected, initialTaxonomyId, bottomContent,
}: Props) {
  const { pathname, state } = useLocation()

  const {
    watch, formState: { errors }, setValue, register,
  } = useForm<ITaxonomy>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { affiliate: '', businessUnit: '', segment: '' },
  })

  const editCapability = useMemo(() => {
    const isEdit = pathname === ROUTES.createDatabaseEdit
    if (isEdit && state) {
      const { taxonomyId } = state as IEditCreateDatabaseFormFields
      const isValid = taxonomySchema.isValidSync({ taxonomyId })
      const canEditCreate = isValid && isEdit
      return { canEditCreate }
    }
    return { canEditCreate: false }
  }, [pathname, state])
  const { canEditCreate } = editCapability

  const values = watch()
  const { data: segmentResponse, isFetching: isSegmentLoading } = useFetchSegment()
  const segments = React.useMemo(() => {
    if (segmentResponse?.data.length) {
      return segmentResponse.data.filter((segment) => (!!segment.trim()))
    }
    return []
  }, [segmentResponse])

  const {
    data: businessUnitResponse,
    isFetching: isBusinessUnitLoading,
  } = useFetchBusinessUnit(values.segment, {
    onSettled: () => {
      setValue('businessUnit', '')
      setValue('affiliate', '')
    },
  })

  const businessUnits = businessUnitResponse?.data ?? []

  const { data: affiliatesResponse, isFetching: isAffiliatesLoading } = useFetchAffiliates(
    values.segment,
    businessUnits.find((b) => values.businessUnit === b.businessUnitId.toString())?.businessName ?? '',
  )
  const affiliates = affiliatesResponse?.data ?? []

  const isLoading = isAffiliatesLoading || isBusinessUnitLoading || isSegmentLoading

  const onLoadingRef = useRef(onLoading)

  useEffect(() => {
    onLoadingRef.current = onLoading
  }, [onLoading])

  useEffect(() => { onLoadingRef.current(isLoading) }, [isLoading])

  const selectedTaxonomy = useMemo(() => {
    if (values.affiliate.length > 0) {
      return values.affiliate
    } if (values.businessUnit.length > 0) {
      return values.businessUnit
    }
    if (canEditCreate) {
      const { taxonomyId } = state as IEditCreateDatabaseFormFields
      return taxonomyId
    }
    if (initialTaxonomyId) {
      return initialTaxonomyId
    }
    return undefined
  }, [values.affiliate, values.businessUnit, canEditCreate, initialTaxonomyId, state])

  const getSelectedTaxonomyText = useMemo(() => {
    if (initialTaxonomyId) return `Change Taxonomy: ${selectedTaxonomy}`
    if (selectedTaxonomy) return `Selected Taxonomy: ${selectedTaxonomy}`
    return undefined
  }, [selectedTaxonomy, initialTaxonomyId])

  const onSelectedRef = useRef(onSelected)

  useEffect(() => {
    onSelectedRef.current = onSelected
  }, [onSelected])

  useEffect(() => { onSelectedRef.current(selectedTaxonomy) }, [selectedTaxonomy])

  return (
    <Panel className="mt-5" title={getSelectedTaxonomyText} titleClassName="">
      <PanelBody>
        <Panel title="Taxonomy">
          <PanelBody>
            <Form.Field>
              <Label label="Segment" for="segment" required />
              <Form.Control>
                <div className="select is-fullwidth">
                  <select id="segment" {...register('segment')}>
                    <option disabled value="">Enter a Segment</option>
                    {segments.map((value, i) => <option key={`${value + i}key`} value={value}>{value}</option>)}
                  </select>
                </div>
              </Form.Control>
            </Form.Field>
            {businessUnits.length > 0
              && (
                <Form.Field>
                  <Label label="Business Unit" for="businessUnit" required />
                  <Form.Control>
                    <div className="select is-fullwidth">
                      <select id="businessUnit" {...register('businessUnit')}>
                        <option disabled value="">Enter a Business Unit</option>
                        {businessUnits.map((businessUnit) => (
                          <option
                            key={businessUnit._id}
                            value={businessUnit.businessUnitId}
                          >
                            {`${businessUnit.businessName} (${businessUnit.businessUnitId})`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Form.Control>
                  <Form.Help color="danger">{errors.businessUnit != null ? errors.businessUnit.message : ''}</Form.Help>
                </Form.Field>
              )}
            {affiliates.length > 0
              && (
                <Form.Field>
                  <Label label="Affiliate" />
                  <Form.Control>
                    <div className="select is-fullwidth">
                      <select id="affiliate" {...register('affiliate')}>
                        <option disabled value="">Enter an Affiliate</option>
                        {affiliates.map((affiliate) => (
                          <option key={affiliate.affiliateId} value={affiliate.affiliateId}>
                            {`${affiliate.affiliateName} (${affiliate.affiliateId})`}
                          </option>
                        ))}
                      </select>
                    </div>
                    <Form.Help color="danger">{errors.affiliate != null ? errors.affiliate.message : ''}</Form.Help>
                  </Form.Control>
                </Form.Field>
              )}
          </PanelBody>
        </Panel>
        {bottomContent}
      </PanelBody>
    </Panel>
  )
}

Taxonomy.defaultProps = {
  initialTaxonomyId: undefined,
  bottomContent: undefined,
}

export default Taxonomy
