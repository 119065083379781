import { faCaretLeft, faDatabase, faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import * as yup from 'yup'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Button } from '@bespin-ui/react-ui-components'
import {
  AppSpinner,
  ErrorBannerNotification,
  Label, Panel, PanelBody, SuccessBannerNotification, Taxonomy,
} from '../../components'
import { ROUTES, TITLES } from '../../constants'
import { useFetchDatabaseById, useResetDatabasePassword, useUpdateDatabaseTaxonomy } from '../../hooks/api/databases'
import ICreateDataBaseFormData from '../../types/forms/database'
import OwnersTable from '../../components/OwnersTable/OwnersTable'
import { useAuth } from '../../providers/AuthProvider'
import {
  Columns, Container, Form, Icon,
} from '../../components/bulma'

type IAdminPasswordFields = Pick<ICreateDataBaseFormData, 'password' | 'passwordConfirmation'>

interface INavigationParams {
  databaseID: string
}

const schema = yup.object({
  password: yup.string()
    .required('is required')
    .notOneOf([yup.ref('user')], 'cannot be username')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@&^#$!`*'\-%])[A-Za-z\d\\@&^#$!`*'\-%]{10,128}$/,
      { message: "Password should meet specified requirements. It should satisfy all of these combinations: length 10-128, [a-z], [A-Z], numbers, @, &, ^, #, $, !, `, *, ', -, %,;, cannot be username" },
    ),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Password must match'),
})

function EditDatabase() {
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [isTaxonomyLoading, setIsTaxonomyLoading] = useState(false)
  const [selectedTaxonomy, setSelectedTaxonomy] = useState<string>()
  const [isOwnerLoading, setIsOwnerLoading] = useState(false)
  const [isOwnerError, setIsOwnerError] = useState(false)

  const { isAdmin } = useAuth()

  const navigate = useNavigate()

  const {
    mutate: mutateTaxonomy, isLoading: isTaxonomyMutating,
    reset: resetTaxonomy, isError: isTaxonomyError,
    isSuccess: isTaxonomySuccess,
  } = useUpdateDatabaseTaxonomy()

  const {
    watch, formState: { errors, isValid }, handleSubmit, setValue, clearErrors, setError, register,
  } = useForm<IAdminPasswordFields>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  })
  const values = watch()

  const { databaseID } = useParams<keyof INavigationParams>()

  const {
    data: databaseResponse,
    isFetching: isDatabaseLoading, isError: isDatabaseError,
    isSuccess: isDatabaseSuccess,
  } = useFetchDatabaseById({ databaseID, isOracle: false })

  const database = databaseResponse?.data

  const {
    mutate: mutatePassword, isError: isResetPasswordError,
    error: resetPasswordError,
    reset: clearResetPasswordError,
    isLoading: isResetPasswordLoading,
    isSuccess: isResetPasswordSuccess,
  } = useResetDatabasePassword({
    onSettled: () => {
      setValue('password', '')
      setValue('passwordConfirmation', '')
    },
  })

  const isLoading = isDatabaseLoading || isTaxonomyLoading
    || isTaxonomyMutating || isOwnerLoading || isResetPasswordLoading

  const onSubmit = () => {
    if (isResetPasswordError) {
      clearResetPasswordError()
    }
    mutatePassword({
      databaseID: databaseID ?? '',
      password: values.password,
    })
  }

  useEffect(() => {
    if (isDatabaseError) {
      navigate(ROUTES.listDatabases, { replace: true })
    }
  }, [isDatabaseError, navigate])

  useEffect(() => {
    if (!errors.password && values.password === values.passwordConfirmation) {
      clearErrors('passwordConfirmation')
    } else if (errors.password && values.passwordConfirmation.trim() !== '') {
      setError('passwordConfirmation', { type: 'validate', message: 'Password must match' })
    }
  }, [values.password, values.passwordConfirmation, clearErrors, setError, errors.password])

  return (
    <>
      <Helmet>
        <title>{TITLES.editDatabase}</title>
      </Helmet>
      <AppSpinner isLoading={isLoading}>
        <Container>
          {isDatabaseSuccess
            && (
              <>
                <Columns multiline={false} className="mt-5">
                  <Columns.Column size={12}>
                    <h4 className="title is-4">
                      <Link to={ROUTES.listDatabases} replace>
                        <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
                      </Link>
                      <FontAwesomeIcon className="pr-3" icon={faDatabase} />
                      Databases &gt; Edit Database
                      {' '}
                      {database?.databaseName != null ? `${database?.databaseName} : ` : ''}
                    </h4>
                  </Columns.Column>
                </Columns>
                <Columns>
                  <Columns.Column size={12}>
                    <Panel title="Reset Password">
                      <PanelBody>
                        <Form.Field>
                          <Label label="Admin Password" required />
                          <Form.Control>
                            <input
                              type={showPassword ? 'text' : 'password'}
                              className={classNames('input', { 'is-danger': errors.password != null })}
                              {...register('password')}
                              id="password"
                            />
                            <Icon align="right">
                              <FontAwesomeIcon
                                icon={faEye}
                                color="rgb(74, 74, 74)"
                                cursor="pointer"
                                pointerEvents="initial"
                                onClick={() => setShowPassword(!showPassword)}
                              />
                            </Icon>
                          </Form.Control>
                          <Form.Help color="danger">{errors.password != null ? errors.password.message : ''}</Form.Help>

                        </Form.Field>
                        <Form.Field>
                          <Label label="Admin Password Confirmation" required />
                          <Form.Control>
                            <input
                              type={showConfirmPassword ? 'text' : 'password'}
                              className={classNames('input', { 'is-danger': errors.passwordConfirmation != null })}
                              {...register('passwordConfirmation')}
                              id="passwordConfirmation"
                            />
                            <Icon align="right">
                              <FontAwesomeIcon
                                icon={faEye}
                                color="rgb(74, 74, 74)"
                                cursor="pointer"
                                pointerEvents="initial"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              />
                            </Icon>
                          </Form.Control>
                          <Form.Help color="danger">{errors.passwordConfirmation != null ? errors.passwordConfirmation.message : ''}</Form.Help>
                        </Form.Field>

                        <Form.Field className="is-flex is-flex-direction-row-reverse mt-5">
                          <Button
                            isDisabled={!isValid || isResetPasswordLoading}
                            onClick={handleSubmit(onSubmit)}
                            label="Reset password"
                            variant="primary"
                          />
                        </Form.Field>
                        {isResetPasswordError && (
                          <ErrorBannerNotification
                            message={resetPasswordError?.message ?? ''}
                            dismissButtonCallback={clearResetPasswordError}
                          />
                        )}
                        {(!isResetPasswordError && isResetPasswordSuccess) && (
                          <SuccessBannerNotification
                            dismissButtonCallback={clearResetPasswordError}
                            message="Password has updated successfully."
                          />
                        )}
                      </PanelBody>
                    </Panel>
                  </Columns.Column>

                  <Columns.Column size={12}>
                    <Panel title="Additional Owners">
                      <PanelBody>
                        <OwnersTable
                          initialValues={database?.owners ?? []}
                          databaseId={databaseID}
                          onLoadingStatus={setIsOwnerLoading}
                          onError={setIsOwnerError}
                        />
                        {isOwnerError && (
                          <ErrorBannerNotification
                            message="Unexpected error occurred while updating owners!. Please try again or contact admin."
                            dismissButtonCallback={() => { setIsOwnerError(false) }}
                          />
                        )}
                      </PanelBody>
                    </Panel>
                  </Columns.Column>
                  {isAdmin
                    && (
                      <Columns.Column size={12}>
                        <Taxonomy
                          initialTaxonomyId={database?.taxonomyId}
                          onLoading={setIsTaxonomyLoading}
                          onSelected={(id) => {
                            if (id !== selectedTaxonomy) { resetTaxonomy() }
                            setSelectedTaxonomy(id)
                          }}
                          bottomContent={(
                            <Columns>
                              <Columns.Column size={12}>
                                <Form.Field className="is-flex is-flex-direction-row-reverse mt-5">
                                  <Button
                                    label="Update Taxonomy"
                                    isDisabled={isTaxonomyMutating
                                      || database?.taxonomyId === selectedTaxonomy}
                                    variant="primary"
                                    onClick={() => {
                                      mutateTaxonomy({ databaseID: databaseID ?? '', selectedTaxonomyId: selectedTaxonomy ?? '' })
                                    }}
                                  />
                                </Form.Field>
                              </Columns.Column>
                              <Columns.Column size={12}>
                                {isTaxonomyError && (
                                  <ErrorBannerNotification
                                    message="Unexpected error occurred while updating taxonomy!. Please try again or contact admin."
                                    dismissButtonCallback={() => { resetTaxonomy() }}
                                  />
                                )}
                                {(!isTaxonomyError && isTaxonomySuccess) && (
                                  <SuccessBannerNotification
                                    dismissButtonCallback={resetTaxonomy}
                                    message="Taxonomy has updated successfully."
                                  />
                                )}
                              </Columns.Column>
                            </Columns>
                          )}
                        />
                      </Columns.Column>
                    )}
                </Columns>
              </>
            )}
        </Container>
      </AppSpinner>
    </>
  )
}

export default EditDatabase
