import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Dialog } from '@bespin-ui/react-ui-components'

function ConfirmModal({
  isOpen,
  cancelButton,
  okayButton,
  title,
  description,
  isReverseActionButtonsPosition,
  handleClose,
}: {
  title: string,
  description: string,
  isOpen: boolean,
  cancelButton: JSX.Element,
  okayButton: JSX.Element,
  isReverseActionButtonsPosition?: boolean,
  handleClose(): void,
}) {
  const [content, setContent] = useState({ title: '', description: '' })

  useEffect(() => {
    if (isOpen) {
      setContent({ title, description })
    }
  }, [isOpen, title, description])

  return (
    <Dialog
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <h4 className="title is-4 mt-5">{content.title}</h4>
      <p className="subtitle is-6 mt-5 mb-5">{content.description}</p>
      <div className={classNames(
        'is-flex pt-5',
        { 'is-flex-direction-row-reverse': isReverseActionButtonsPosition === true },
        { 'is-justify-content-flex-end': isReverseActionButtonsPosition == null || isReverseActionButtonsPosition === false },
      )}
      >
        {okayButton}
        &nbsp;
        &nbsp;
        {cancelButton}
      </div>
    </Dialog>
  )
}

ConfirmModal.defaultProps = {
  isReverseActionButtonsPosition: undefined,
}

export default ConfirmModal
