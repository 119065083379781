/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  MutableRefObject, useMemo, useRef, useState,
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faCaretLeft } from '@fortawesome/free-solid-svg-icons'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Icon } from '@bespin-ui/react-ui-components'
import classNames from 'classnames'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, useWatch } from 'react-hook-form'
import styled from 'styled-components'
import { BiPlus, BiTrash } from 'react-icons/bi'
// import { useDebouncedCallback } from 'use-debounce'
import type SelectClass from 'react-select/dist/declarations/src/Select'
// import Select from 'react-select'
import * as bulmaToast from 'bulma-toast'
import { Container, Form } from '../../components/bulma'
import {
  ErrorBannerNotification,
  Label, Panel, PanelBody, Taxonomy,
} from '../../components'
import { ROUTES } from '../../constants'
import AppLayout from '../../layouts'
import { useAuth } from '../../providers/AuthProvider'
import { useFetchIsAdmin } from '../../hooks/api/admin'

import { useCreateOrganization, ICreateOrganizationPayload } from '../../hooks/api/organizations'
import { useUserInfo } from '../../hooks/auth'

const schema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string(),
  costCenter: yup.string().required('Cost center is required'),
  taxonomyId: yup.string().required('Taxonomy ID is required'),
  execContactEmail: yup.string().email('Enter a valid email').required('Email is required'),
  techContactEmail: yup.string().email('Enter a valid email').required('Email is required'),
  slackChannel: yup.string().required('Slack channel is required'),
  serviceNowGroup: yup.string().required('serviceNowGroup is required'),
  pagerDutyId: yup.string().required('Pager Duty Id is required'),
  requestor: yup.string(),
  atlasOrgUsers: yup.array().of(yup.object().shape({
    username: yup.string().nullable(),
    roles: yup.array(yup.string()).nullable(),
  })),
})

const Table = styled.table`
  background-color: #fff;
  color: #363636;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  display: table;
  font-size: 14px;
  th, td:not(.last-row) {
    text-align: left;
    padding: 8px;
    border: 1px solid #ddd
  }
  .last-row {
    border: 1px solid #ddd;
    padding: 4px 8px;
  }
`
const StyledTd = styled.td`
  position: relative;
`
const IconActionButton = styled.span`
  position: absolute;
  top: 50%;
  left: auto;
  right: 0;
  transform: translate(-50%, -50%);
  button {
    height: 0;
    padding: 10px 5px;
  } 
`

interface IAdminOption {
  label?: string,
  value?: string,
  id: string;
  hubId: string;
  name: string;
  email: string
}

type IAdmin = Omit<IAdminOption, 'label' | 'value'>

const mockResponse = async (_newValue: string) => {
  if (_newValue) {
    return [
      {
        id: 'testone', hubId: 'testone', name: 'test one', email: 'testone@email.com',
      },
      {
        id: 'testtwo', hubId: 'testtwo', name: 'test two', email: 'testtwo@email.com',
      },
      {
        id: 'testthree', hubId: 'testthree', name: 'test three', email: 'testthree@email.com',
      },
      {
        id: 'testfour', hubId: 'testfour', name: 'test four', email: 'testfour@email.com',
      },
      {
        id: 'testfive', hubId: 'testfive', name: 'test five', email: 'testfive@email.com',
      },
      {
        id: 'testsix', hubId: 'testsix', name: 'test six', email: 'testsix@email.com',
      },
      {
        id: 'testseven', hubId: 'testseven', name: 'test seven', email: 'testseven@email.com',
      },
    ]
  }
  return null
}

function CreateOrganizationPage() {
  const adminSelectRef = useRef() as MutableRefObject<SelectClass<IAdmin>>
  const [adminList, setAdminList] = useState<IAdminOption[]>([])
  const [emailOwner, setOwnerEmail] = useState('')
  const [ownerHubId, setOwnerHubId] = useState('')
  // const [selectedAdminId, setSelectedAdminId] = useState<IAdmin | undefined>()
  // const [selectedAdminIds, setSelectedAdminIds] = useState<IAdmin[]>([])
  const { isAuthenticated } = useAuth()
  const { uid: userID, email: userEmail } = useUserInfo()
  const { isLoading: isAuthLoading } = useFetchIsAdmin({ enabled: !isAuthenticated })
  const navigation = useNavigate()

  // const { get } = useDBaaSClient()
  const {
    mutateAsync, isLoading: isMutating, isError, reset,
  } = useCreateOrganization()

  // const debouncedAdminFetch = useDebouncedCallback((texts: string) => {
  //   mockResponse(texts).then((values) => {
  //     const formatted = values?.map((entry) => ({
  //       ...entry,
  //       value: entry.name,
  //       label: entry.name,
  //     }))
  //     setAdminList(formatted ?? [])
  //   })
  // }, 1000)

  const {
    formState: { errors, isValid }, register, handleSubmit, setValue, control,
    getValues,
  } = useForm<ICreateOrganizationPayload>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { requestor: userID },
  })

  const { atlasOrgUsers } = useWatch({ control })

  const [isTaxonomyLoading, setIsTaxonomyLoading] = useState(false)
  const isLoading = useMemo(() => isTaxonomyLoading || isAuthLoading || isMutating, [
    isAuthLoading, isMutating, isTaxonomyLoading,
  ])
  const isOwnerValid = useMemo(() => {
    const emailRegex = /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/gm
    const listHubIds = getValues('atlasOrgUsers') ?? []
    let emailAlreadyInUse = false
    let hubIdAlreadyInUse = false
    const isValidEmail = emailRegex.test(emailOwner)
    if (isValidEmail) {
      emailAlreadyInUse = listHubIds.some((entry) => entry.email === emailOwner)
    }
    if (hubIdAlreadyInUse) {
      hubIdAlreadyInUse = ownerHubId.length > 2
    }
    hubIdAlreadyInUse = listHubIds.some((entry) => entry.username === ownerHubId)
    return {
      isEmailValid:
      isValidEmail && !emailAlreadyInUse,
      isHubIdValid: ownerHubId.length > 2 && !hubIdAlreadyInUse,
    }
  }, [emailOwner, getValues, ownerHubId])

  return (
    <AppLayout isLoading={isLoading}>
      <Container className="is-fluid">
        <h4 className="title is-4">
          <Link to={ROUTES.listOrganization} replace>
            <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
          </Link>
          <FontAwesomeIcon className="pr-3" icon={faDatabase} />
          Organization &gt; Create
        </h4>
        {isError && (
        <ErrorBannerNotification
          message="Unexpected error occurred!. Check your input and try again or contact DBaaS support."
          dismissButtonCallback={reset}
        />
        )}
        <Panel title="New Organization">
          <PanelBody>
            <form onSubmit={(e) => { e.preventDefault() }}>
              <Form.Field>
                <Label label="Organization Name" for="name" required />
                <Form.Control>
                  <input
                    id="name"
                    data-testid="name"
                    className={classNames('input', { 'is-danger': errors.name != null })}
                    {...register('name')}
                  />
                </Form.Control>
                {errors.name && <Form.Help color="danger">{errors.name.message}</Form.Help>}
              </Form.Field>

              <Form.Field>
                <Label label="Description" for="description" required />
                <Form.Control>
                  <Form.TextArea
                    id="description"
                    data-testid="description"
                    className={classNames('input', { 'is-danger': errors.description != null })}
                    {...register('description')}
                  />
                </Form.Control>
                {errors.description && <Form.Help color="danger">{errors.description.message}</Form.Help>}
              </Form.Field>

              <Form.Field>
                <Label label="Cost Center" for="name" required />
                <Form.Control>
                  <input
                    id="costCenter"
                    data-testid="costCenter"
                    className={classNames('input', { 'is-danger': errors.costCenter != null })}
                    {...register('costCenter')}
                  />
                </Form.Control>
                {errors.costCenter && <Form.Help color="danger">{errors.costCenter.message}</Form.Help>}
              </Form.Field>

              <Taxonomy
                onLoading={(loading: boolean) => {
                  setIsTaxonomyLoading(loading)
                }}
                onSelected={(selectedId) => {
                  if (selectedId != null) { setValue('taxonomyId', selectedId) }
                }}
              />

              <Form.Field>
                <Label label="Executive Owner Email" for="execContactEmail" required />
                <Form.Control>
                  <input
                    id="execContactEmail"
                    data-testid="execContactEmail"
                    className={classNames('input', { 'is-danger': errors.execContactEmail != null })}
                    {...register('execContactEmail')}
                  />
                </Form.Control>
                {errors.execContactEmail && <Form.Help color="danger">{errors.execContactEmail.message}</Form.Help>}
              </Form.Field>

              <Form.Field>
                <Label label="Tech Owner Email" for="techContactEmail" required />
                <Form.Control>
                  <Form.Input
                    id="techContactEmail"
                    data-testid="techContactEmail"
                    color={errors.techContactEmail != null ? 'danger' : undefined}
                    {...register('techContactEmail')}
                  />
                </Form.Control>
                {errors.techContactEmail && <Form.Help color="danger">{errors.techContactEmail.message}</Form.Help>}
              </Form.Field>

              <Form.Field>
                <Label label="Slack Channel" for="slackChannel" required />
                <Form.Control>
                  <Form.Input
                    id="slackChannel"
                    data-testid="slackChannel"
                    color={errors.slackChannel != null ? 'danger' : undefined}
                    {...register('slackChannel')}
                  />
                </Form.Control>
                {errors.slackChannel && <Form.Help color="danger">{errors.slackChannel.message}</Form.Help>}
              </Form.Field>

              <Form.Field>
                <Label label="Service Now Group" for="serviceNowGroup" required />
                <Form.Control>
                  <Form.Input
                    id="serviceNowGroup"
                    data-testid="serviceNowGroup"
                    color={errors.serviceNowGroup != null ? 'danger' : undefined}
                    {...register('serviceNowGroup')}
                  />
                </Form.Control>
                {errors.serviceNowGroup && <Form.Help color="danger">{errors.serviceNowGroup.message}</Form.Help>}
              </Form.Field>

              <Form.Field>
                <Label label="Pager Duty Id" for="pagerDutyId" required />
                <Form.Control>
                  <Form.Input
                    id="pagerDutyId"
                    data-testid="pagerDutyId"
                    color={errors.pagerDutyId != null ? 'danger' : undefined}
                    {...register('pagerDutyId')}
                  />
                </Form.Control>
                {errors.pagerDutyId && <Form.Help color="danger">{errors.pagerDutyId.message}</Form.Help>}
              </Form.Field>

              <Form.Field>
                <Label label="Created By" for="requestor" required />
                <Form.Control>
                  <Form.Input
                    id="requestor"
                    data-testid="requestor"
                    color={errors.requestor != null ? 'danger' : undefined}
                    {...register('requestor')}
                    disabled
                  />
                </Form.Control>
                {errors.requestor && <Form.Help color="danger">{errors.requestor.message}</Form.Help>}
              </Form.Field>

              <Panel className="mt-5">
                <PanelBody>
                  <Panel title="Additional Owners">
                    <PanelBody>
                      <Table>
                        <colgroup>
                          <col width="40%" />
                          <col width="40%" />
                          <col width="20%" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>Username</th>
                            {/* <th>Name</th> */}
                            <th>Email</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {atlasOrgUsers?.map((entry) => (
                            <tr key={entry.email}>
                              <td>
                                {entry.username}
                              </td>
                              <td>
                                {entry.email}
                              </td>
                              <StyledTd>
                                <IconActionButton>
                                  <Button
                                    icon={<Icon icon={<BiTrash />} />}
                                    onClick={() => {
                                      if (atlasOrgUsers != null) {
                                        setValue(
                                          'atlasOrgUsers',
                                          atlasOrgUsers.filter((hub) => hub.email !== entry.email),
                                        )
                                      }
                                    }}
                                    label=""
                                  />
                                </IconActionButton>
                              </StyledTd>
                            </tr>
                          ))}
                          <tr>
                            <td>
                              <Form.Field>
                                <Form.Control fullwidth={false}>
                                  <Form.Input
                                    id="hubId"
                                    value={ownerHubId}
                                    style={{ width: '100%' }}
                                    size="small"
                                    name="owner"
                                    onChange={(e) => setOwnerHubId(e.currentTarget.value)}
                                    color={!isOwnerValid.isHubIdValid && ownerHubId.length ? 'danger' : undefined}
                                  />
                                </Form.Control>
                              </Form.Field>
                            </td>
                            <td>
                              <Form.Field>
                                <Form.Control fullwidth={false}>
                                  <Form.Input
                                    id="email"
                                    value={emailOwner}
                                    style={{ width: '100%' }}
                                    size="small"
                                    name="owner"
                                    onChange={(e) => setOwnerEmail(e.currentTarget.value)}
                                    color={!isOwnerValid.isEmailValid && emailOwner.length ? 'danger' : undefined}
                                  />
                                </Form.Control>
                              </Form.Field>
                            </td>
                            <StyledTd>
                              <IconActionButton id="addOwnerBtn">
                                <Button
                                  onClick={() => {
                                    const data = {
                                      username: ownerHubId,
                                      email: emailOwner,
                                      roles: ['ORG_OWNER'],
                                    }
                                    const listOwner = getValues('atlasOrgUsers') ?? []
                                    if (listOwner.length) {
                                      if (!listOwner.some((entry) => entry.email === data.email
                                      || entry.username === data.username)) {
                                        setValue('atlasOrgUsers', [...listOwner, data])
                                      }
                                      setOwnerEmail('')
                                      setOwnerHubId('')
                                      return
                                    }
                                    setValue('atlasOrgUsers', [data])
                                    setOwnerEmail('')
                                    setOwnerHubId('')
                                  }}
                                  icon={<Icon icon={<BiPlus />} />}
                                  label=""
                                  isDisabled={
                                    !isOwnerValid.isEmailValid || !isOwnerValid.isHubIdValid
                                  }
                                />
                              </IconActionButton>
                            </StyledTd>
                          </tr>
                        </tbody>
                      </Table>
                    </PanelBody>
                  </Panel>
                </PanelBody>
              </Panel>

              <Form.Field className="is-flex is-justify-content-space-between mt-5">
                <Button
                  label="Cancel"
                  onClick={() => {
                    navigation(ROUTES.listOrganization, { replace: true })
                  }}
                />
                <Button
                  isDisabled={isLoading || !isValid}
                  label="Submit"
                  variant="primary"
                  onClick={() => {
                    handleSubmit((value) => {
                      const data = {
                        ...value,
                      }
                      if (Array.isArray(data.atlasOrgUsers)) {
                        data.atlasOrgUsers.unshift({ email: userEmail, roles: ['ORG_OWNER'], username: userID })
                      } else {
                        data.atlasOrgUsers = [{ email: userEmail, roles: ['ORG_OWNER'], username: userID }]
                      }
                      mutateAsync(data, {
                        onSuccess: () => {
                          bulmaToast.toast({
                            message: `<b>${value.name}</b> Organization is successfully created`,
                            type: 'is-success',
                            dismissible: true,
                            duration: 6000,
                            position: 'bottom-right',
                            animate: { in: 'fadeIn', out: 'fadeOut' },
                          })
                          navigation(ROUTES.listOrganization, { replace: true })
                        },
                        onError: () => {
                          window.scrollTo({ top: 0, behavior: 'smooth' })
                        },
                      })
                    })()
                  }}
                />
              </Form.Field>
            </form>
          </PanelBody>
        </Panel>
      </Container>
    </AppLayout>
  )
}

export default CreateOrganizationPage
