import React, { useEffect, useMemo } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import jwt from 'jwt-decode'
import { openIdConfig } from '../../config/config'
import { authorizationEndpoint } from '../../config/api'
import CredentialContext from './CredentialContext'

function CredentialProvider({ children }: { children: React.ReactNode }) {
  const location = useLocation()

  const { hash, pathname } = location
  const isRedirectPath = openIdConfig.redirectUri === pathname
  const isUnProtectedRoute = pathname === '/'

  const accessTokenObject = useMemo(() => {
    if (isRedirectPath && (hash.length > 0)) {
      const { access_token: accessToken = '' } = queryString.parse(hash)

      if (typeof accessToken === 'string' && accessToken.length > 0) {
        return { accessToken }
      }
      const token = sessionStorage.getItem('access_token')
      return { accessToken: (token != null) ? token : '' }
    }
    const token = sessionStorage.getItem('access_token')
    return { accessToken: (token != null) ? token : '' }
  }, [isRedirectPath, hash])

  const { accessToken } = accessTokenObject

  useEffect(() => {
    if (!isUnProtectedRoute) {
      let timer: NodeJS.Timeout | null = null

      const redirectToAuthEndPoint = () => {
        if (!isRedirectPath) {
          sessionStorage.setItem('current_path', window.location.pathname)
        }
        window.location.href = authorizationEndpoint
      }

      if (accessToken != null) {
        if (accessToken.length === 0) {
          redirectToAuthEndPoint()
          return () => { /* */ }
        }
        try {
          const { exp }: { exp: number } = jwt(accessToken)
          const now = Date.now()
          const accessTokenExpiration = new Date(exp * 1000)
          const msRemaining = accessTokenExpiration.getTime() - now
          const isValid = msRemaining > 0
          if (!isValid) {
            redirectToAuthEndPoint()
            return () => { /* */ }
          }
          timer = setTimeout(() => {
            redirectToAuthEndPoint()
          }, msRemaining)
        } catch (error) {
          redirectToAuthEndPoint()
          return () => { /* */ }
        }
      }
      return () => {
        if (timer != null) {
          clearTimeout(timer)
        }
      }
    }
    return () => { /* */ }
  }, [isRedirectPath, accessToken, isUnProtectedRoute])

  const value = useMemo(() => {
    if (accessToken) {
      const { email, uid, name } = jwt<{
        email: string, uid: string, name: string
      }>(accessToken)
      return {
        email, uid, name, accessToken,
      }
    }
    return {
      email: undefined, uid: undefined, name: undefined, accessToken: undefined,
    }
  }, [accessToken])

  return (
    <CredentialContext.Provider value={value}>
      {children}
    </CredentialContext.Provider>
  )
}

export default CredentialProvider
