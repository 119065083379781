import { faCaretLeft, faDatabase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import {
  createSearchParams, Link, useLocation, useNavigate,
} from 'react-router-dom'
import { Button } from '@bespin-ui/react-ui-components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Resolver, useFieldArray, useForm } from 'react-hook-form'
import { Helmet } from 'react-helmet'
import { BiXCircle } from 'react-icons/bi'
import styled from 'styled-components'
import {
  ConfirmModal, Label, Panel, PanelBody,
} from '../../components'
import {
  Columns, Container, Form,
} from '../../components/bulma'
import { ROUTES, TITLES } from '../../constants'
import { useFetchIsAdmin } from '../../hooks/api/admin'
import AppLayout from '../../layouts'
import { useFetchSegment } from '../../hooks/api/taxonomy'

interface IKeyPair {
  secret: string,
  key: string,
  awsUsername: string
}

interface IVPC {
  env: string,
  vpcId: string,
  subnetGroup: string,
  regionName: string,
}

interface IFormFields {
  type: string,
  segment: string,
  inService: boolean,
  atCapacity: string,
  accountName: string,
  acctId: string,
  description: string,
  activeKeyPair: string,
  vpcs: IVPC[],
  keyPair1: IKeyPair,
  keyPair2: IKeyPair
}

const keyPairSchema = yup.object().shape({
  secret: yup.string().required('Secret is a required field').matches(/^\S*$/, 'Whitespace is not allowed'),
  key: yup.string().required('Key is a required field').matches(/^\S*$/, 'Whitespace is not allowed'),
  awsUsername: yup.string().required('AWS Username is a required field').matches(/^\S*$/, 'Whitespace is not allowed'),
})

const vpcSchema = yup.object().shape({
  regionName: yup.string().required('Region name is a required field'),
  vpcId: yup.string().required('VPC ID is a required field').matches(/^\S*$/, 'Whitespace is not allowed')
    .max(30, 'Max length is 30'),
  subnetGroup: yup.string().required('Subnet group is a required field'),
  env: yup.string(),
})

const VPCWrapper = styled.div`
  position: relative;
  svg {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 5px;
    left: auto;
  }
`

const isValidKeyPair = (value: Partial<IKeyPair>) => {
  try {
    keyPairSchema.validateSync(value)
    return true
  } catch (error) {
    return false
  }
}

function CreateCloudAccount() {
  useFetchIsAdmin()

  const navigator = useNavigate()

  const location = useLocation()

  const { isLoading, data: segmentResponse } = useFetchSegment()

  const schema = React.useMemo(() => yup.object().shape({
    type: yup.string().required(),
    segment: yup.string().required(),
    inService: yup.boolean(),
    atCapacity: yup.string(),
    accountName: yup.string().required().trim(),
    activeKeyPair: yup.string(),
    acctId: yup.string().required().matches(/^\S*$/, 'Whitespace is not allowed'),
    description: yup.string().nullable(true),
    vpcs: yup.array().of(vpcSchema).min(1),
    keyPair1: keyPairSchema,
    keyPair2: yup.object().when(['activeKeyPair'], {
      is: (value: string) => value === '2',
      then: keyPairSchema.required(),
      otherwise: yup.object().nullable(true).shape({
        secret: yup.string().nullable(true),
        key: yup.string().nullable(true),
        awsUsername: yup.string().nullable(true),
      }),
    }),
  }), [])

  const {
    formState: { errors, isValid }, register, setValue, watch,
    control,
    handleSubmit,
  } = useForm<IFormFields>({
    resolver: yupResolver(schema) as Resolver<IFormFields>,
    mode: 'onChange',
    defaultValues: {
      type: 'AWS',
      inService: false,
      activeKeyPair: '1',
      vpcs: [
        {
          env: 'DEV',
          vpcId: '',
          subnetGroup: '',
          regionName: 'us-west-2',
        },
      ],
      keyPair1: {
        key: '',
        secret: '',
        awsUsername: '',
      },
      keyPair2: {
        key: '',
        secret: '',
        awsUsername: '',
      },
    },
  })
  const { append, remove, fields } = useFieldArray({ name: 'vpcs', control })

  const segments = React.useMemo(() => {
    if (segmentResponse?.data.length) {
      return segmentResponse.data.filter((segment) => (!!segment.trim()))
    }
    return []
  }, [segmentResponse])

  React.useEffect(() => {
    if (segments.length) {
      setValue('segment', segments[0])
    }
  }, [segments, setValue])

  const values = watch()

  const [openModel, setOpenModel] = React.useState(false)

  const onSubmit = () => {
    setOpenModel(true)
  }

  const vpcId = React.useId()

  const canGoBack = useMemo(() => location.state != null && 'canGoBack' in location.state && location.state.canGoBack, [location.state])

  const isKeyPair2Required = useMemo(() => values?.keyPair2?.awsUsername?.trim().length > 0
  || values.keyPair2?.secret?.trim().length > 0
  || values.keyPair2?.key?.trim().length > 0, [
    values?.keyPair2?.awsUsername,
    values?.keyPair2?.key,
    values?.keyPair2?.secret,
  ])

  return (
    <AppLayout isLoading={isLoading}>
      <Helmet>
        <title>{TITLES.createCloudAccount}</title>
      </Helmet>

      <ConfirmModal
        isReverseActionButtonsPosition
        isOpen={openModel}
        title="Are you sure you want to create a new cloud account?"
        description="You can not undo this action, are you sure you want to continue?"
        okayButton={(
          <Button
            label="Submit"
            onClick={() => {
              const items = localStorage.getItem('fakeCloudAccounts')
              const keyPairs = [values.keyPair1]

              if (values.activeKeyPair === '2' || isValidKeyPair(values.keyPair2)) {
                keyPairs.push(values.keyPair2)
              }
              const object: Partial<IFormFields & { keyPairs: IKeyPair[] }> = {
                type: values.type,
                segment: values.segment,
                inService: values.inService,
                atCapacity: values.atCapacity,
                acctId: values.acctId,
                description: values.description,
                activeKeyPair: values.activeKeyPair,
                keyPairs,
                vpcs: values.vpcs,
              }
              if (items && Array.isArray(items) && items.length) {
                localStorage.setItem('fakeCloudAccounts', JSON.stringify([object, ...items]))
              } else {
                localStorage.setItem('fakeCloudAccounts', JSON.stringify([object]))
              }
              // eslint-disable-next-line no-console
              console.log(object)
              setOpenModel(false)
              // navigator({
              //   pathname: ROUTES.listAdministrator,
              //   search: createSearchParams({ tab: 'Cloud Accounts' }).toString(),
              // }, { replace: true })
            }}
            color="success"
          />

        )}
        cancelButton={(
          <Button
            label="Cancel"
            isDanger
            onClick={() => { setOpenModel(false) }}
          />
        )}
        handleClose={() => {
          setOpenModel(false)
        }}
      />

      <Container className="is-fluid" data-testid="createCloudAccountId">
        <h4 className="title is-4">
          <Link to={canGoBack ? `${ROUTES.listAdministrator}?${createSearchParams({ tab: 'Cloud Accounts' }).toString()}` : ROUTES.listAdministrator} replace>
            <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
          </Link>
          <FontAwesomeIcon className="pr-3" icon={faDatabase} />
          Cloud Account &gt; Create
        </h4>
        {/* {isError && (
        <ErrorBannerNotification
          message="Unexpected error occurred!.
          Check your input and try again or contact DBaaS support."
          dismissButtonCallback={reset}
        />
        )} */}
        <Panel title="New Cloud Account">
          <PanelBody>
            <form onSubmit={(e) => { e.preventDefault() }}>
              <Form.Field>
                <Label label="Type" for="type" required />
                <Form.Control>
                  <Form.Select
                    fullwidth
                    id="type"
                    {...register('type')}
                    color={errors.type != null ? 'danger' : ''}
                  >
                    <option value="AWS">AWS</option>
                    <option value="GCP" disabled>GCP</option>
                    <option value="Azure" disabled>Azure</option>
                  </Form.Select>
                  <Form.Help color="danger">
                    {errors.type != null ? errors.type.message : ''}
                  </Form.Help>
                </Form.Control>
              </Form.Field>
              <Form.Field>
                <Label label="Segment" for="segment" required />
                <Form.Control>
                  <Form.Select
                    fullwidth
                    id="segment"
                    {...register('segment')}
                    color={errors.segment != null ? 'danger' : ''}
                  >
                    {segments.map((segment, index) => <option key={`${segment + index}`} value={segment}>{segment}</option>)}
                  </Form.Select>
                  <Form.Help color="danger">
                    {errors.segment != null ? errors.segment.message : ''}
                  </Form.Help>
                </Form.Control>
              </Form.Field>
              <Form.Field horizontal mt={5} alignItems="baseline">
                <Form.Field.Label textAlign="left" className="mr-0 title is-6">In Service </Form.Field.Label>
                <Form.Field.Body>
                  <Form.Field>
                    <Form.Control>
                      <Form.Checkbox
                        {...register('inService')}
                        onChange={(e) => {
                          setValue('inService', e.target.checked)
                        }}
                        checked={values.inService ?? false}
                      />
                    </Form.Control>
                  </Form.Field>
                </Form.Field.Body>
              </Form.Field>

              <Form.Field horizontal mt={5} alignItems="baseline">
                <Form.Field.Label textAlign="left" className="mr-0 title is-6">At Capacity</Form.Field.Label>
                <Form.Field.Body>
                  <Form.Field>
                    <Form.Control>
                      <Form.Checkbox
                        onChange={(e) => {
                          if (e.target.checked) {
                            setValue('atCapacity', 'FULL')
                          } else {
                            setValue('atCapacity', '')
                          }
                        }}
                        checked={values.atCapacity === 'FULL' ?? false}
                      />
                    </Form.Control>
                  </Form.Field>
                </Form.Field.Body>
              </Form.Field>

              <Form.Field>
                <Label label="Account Name" for="accountName" required />
                <Form.Control>
                  <Form.Input
                    id="accountName"
                    {...register('accountName')}
                    color={errors.accountName ? 'danger' : undefined}
                    value={values.accountName ?? ''}
                  />
                </Form.Control>
                <Form.Help color="danger">{errors.accountName != null ? errors.accountName.message : ''}</Form.Help>
              </Form.Field>

              <Form.Field>
                <Label label="Account ID" for="acctId" required />
                <Form.Control>
                  <Form.Input
                    id="acctId"
                    {...register('acctId')}
                    color={errors.acctId ? 'danger' : undefined}
                    value={values.acctId ?? ''}
                  />
                </Form.Control>
                <Form.Help color="danger">{errors.acctId != null ? errors.acctId.message : ''}</Form.Help>
              </Form.Field>
              <Form.Field>
                <Label label="Description" for="description" />
                <Form.Control>
                  <Form.Input
                    id="description"
                    {...register('description')}
                    color={errors.description ? 'danger' : undefined}
                    value={values.description ?? ''}
                  />
                </Form.Control>
                <Form.Help color="danger">{errors.description != null ? errors.description.message : ''}</Form.Help>
              </Form.Field>

              <Form.Field horizontal alignItems="baseline" pt={4}>
                <Form.Field.Label
                  textAlign="left"
                  className="mr-0 title is-6"
                >
                  Active Key-pair
                </Form.Field.Label>
                <Form.Field.Body>
                  <Form.Field>
                    <Form.Control>
                      <Form.Radio
                        checked={values.activeKeyPair === '1'}
                        onChange={() => { setValue('activeKeyPair', '1') }}
                        value="1"
                        className="mr-3"
                      >
                        Key-pair 1
                      </Form.Radio>
                      <Form.Radio
                        checked={values.activeKeyPair === '2'}
                        onChange={() => { setValue('activeKeyPair', '2') }}
                        value="2"
                      >
                        Key-pair 2
                      </Form.Radio>
                    </Form.Control>
                  </Form.Field>
                </Form.Field.Body>
              </Form.Field>

              <Panel className="mt-2" title="Key Pair 1">
                <PanelBody>
                  <Form.Field>
                    <Label label="AWS Username" for="keyPair1.awsUsername" required />
                    <Form.Control>
                      <Form.Input
                        id="keyPair1.awsUsername"
                        {...register('keyPair1.awsUsername')}
                        color={errors?.keyPair1?.awsUsername ? 'danger' : undefined}
                        value={values?.keyPair1?.awsUsername ?? ''}
                      />
                    </Form.Control>
                    <Form.Help color="danger">{errors.keyPair1?.awsUsername != null ? errors.keyPair1.awsUsername.message : ''}</Form.Help>
                  </Form.Field>
                  <Columns>
                    <Columns.Column pt={0} pb={0}>
                      <Form.Field>
                        <Label label="Secret 1" for="secret1" required />
                        <Form.Control fullwidth>
                          <Form.Input
                            id="secret1"
                            {...register('keyPair1.secret')}
                            color={errors.keyPair1?.secret ? 'danger' : undefined}
                            value={values.keyPair1?.secret ?? ''}
                          />
                        </Form.Control>
                        <Form.Help color="danger">{errors.keyPair1?.secret != null ? errors.keyPair1?.secret?.message : ''}</Form.Help>
                      </Form.Field>
                    </Columns.Column>

                    <Columns.Column pt={0}>
                      <Form.Field>
                        <Label label="Key 1" for="key1" required />
                        <Form.Control fullwidth>
                          <Form.Input
                            id="key1"
                            {...register('keyPair1.key')}
                            color={errors.keyPair1?.key ? 'danger' : undefined}
                            value={values.keyPair1?.key ?? ''}
                          />
                        </Form.Control>
                        <Form.Help color="danger">{errors.keyPair1?.key != null ? errors.keyPair1?.key?.message : ''}</Form.Help>
                      </Form.Field>
                    </Columns.Column>
                  </Columns>
                </PanelBody>
              </Panel>

              <Panel className="mt-5" title="Key Pair 2">
                <PanelBody>
                  <Form.Field>
                    <Label label="AWS Username" for="keyPair2.awsUsername" required={isKeyPair2Required} />
                    <Form.Control>
                      <Form.Input
                        id="keyPair2.awsUsername"
                        {...register('keyPair2.awsUsername')}
                        color={errors?.keyPair2?.awsUsername ? 'danger' : undefined}
                        value={values?.keyPair2?.awsUsername ?? ''}
                      />
                    </Form.Control>
                    <Form.Help color="danger">{errors.keyPair2?.awsUsername != null ? errors.keyPair2.awsUsername.message : ''}</Form.Help>
                  </Form.Field>
                  <Columns>
                    <Columns.Column pt={0} pb={0}>
                      <Form.Field>
                        <Label label="Secret 2" for="secret2" required={isKeyPair2Required} />
                        <Form.Control>
                          <Form.Input
                            id="secret2"
                            {...register('keyPair2.secret')}
                            color={errors.keyPair2?.secret ? 'danger' : undefined}
                            value={values.keyPair2?.secret ?? ''}
                          />
                        </Form.Control>
                        <Form.Help color="danger">{errors.keyPair2?.secret != null ? errors.keyPair2?.secret?.message : ''}</Form.Help>
                      </Form.Field>
                    </Columns.Column>
                    <Columns.Column pt={0} pb={0}>
                      <Form.Field>
                        <Label label="Key 2" for="key2" required={isKeyPair2Required} />
                        <Form.Control>
                          <Form.Input
                            id="key2"
                            {...register('keyPair2.key')}
                            color={errors.keyPair2?.key ? 'danger' : undefined}
                            value={values.keyPair2.key ?? ''}
                          />
                        </Form.Control>
                        <Form.Help color="danger">{errors.keyPair2?.key != null ? errors.keyPair2?.key?.message : ''}</Form.Help>
                      </Form.Field>
                    </Columns.Column>
                  </Columns>
                </PanelBody>
              </Panel>
              <Panel title="VPCs" className="mt-5">
                <PanelBody>
                  <>
                    {fields.map((v, i) => (
                      <VPCWrapper key={`${vpcId + i}`}>
                        {values.vpcs.length > 1 && (
                          <BiXCircle
                            size={35}
                            scale={1}
                            onClick={() => {
                              remove(i)
                            }}
                          />
                        )}
                        <Panel
                          title={values.vpcs[i].vpcId ? values.vpcs[i].vpcId : 'VPC'}
                          className={i !== 0 ? 'mt-5' : ''}
                        >
                          <PanelBody>
                            <Form.Field>
                              <Label marginless className="mt-2" label="Region Name" for={`name ${i}`} required />
                              <Form.Control>
                                <Form.Select
                                  fullwidth
                                  id={`name ${i}`}
                                  {...register(`vpcs.${i}.regionName`)}
                                  color={errors.vpcs?.[i]?.regionName != null ? 'danger' : ''}
                                >
                                  <option value="us-east-2">US East (Ohio)</option>
                                  <option value="us-east-1">US East (N. Virginia)</option>
                                  <option value="us-west-1">US West (N. California)</option>
                                  <option value="us-west-2">US West (Oregon)</option>
                                </Form.Select>
                              </Form.Control>
                              <Form.Help color="danger">
                                {errors.vpcs?.[i]?.regionName != null ? errors.vpcs[i]?.regionName?.message : ''}
                              </Form.Help>
                            </Form.Field>

                            <Form.Field>
                              <Label marginless className="mt-2" label="VPC ID" for={`vpcId ${i}`} required />
                              <Form.Control>
                                <Form.Input
                                  id={`vpcId ${i}`}
                                  {...register(`vpcs.${i}.vpcId`)}
                                  color={errors.vpcs?.[i]?.vpcId ? 'danger' : undefined}
                                />
                              </Form.Control>
                              <Form.Help color="danger">{errors.vpcs?.[i]?.vpcId != null ? errors.vpcs[i].vpcId?.message : ''}</Form.Help>
                            </Form.Field>

                            <Form.Field>
                              <Label marginless className="mt-2" label="Subnet Group" for={`subnetGroup ${i}`} required />
                              <Form.Control>
                                <Form.Input
                                  id={`subnetGroup ${i}`}
                                  {...register(`vpcs.${i}.subnetGroup`)}
                                  color={errors.vpcs?.[i]?.subnetGroup ? 'danger' : undefined}
                                />
                              </Form.Control>
                              <Form.Help color="danger">{errors.vpcs?.[i]?.subnetGroup != null ? errors.vpcs?.[i]?.subnetGroup?.message : ''}</Form.Help>
                            </Form.Field>

                            <Form.Field>
                              <Label marginless className="mt-2" label="Env" for={`env ${i}`} />
                              <Form.Control>
                                <Form.Select
                                  fullwidth
                                  id={`env ${i}`}
                                  {...register(`vpcs.${i}.env`)}
                                  color={errors.vpcs?.[i]?.env != null ? 'danger' : ''}
                                >
                                  <option value="DEV">{' '}</option>
                                  <option value="DEV">DEV</option>
                                  <option value="QA">QA</option>
                                  <option value="PROD">PROD</option>
                                </Form.Select>
                                <Form.Help color="danger">
                                  {errors.vpcs?.[i]?.env != null ? errors.vpcs?.[i]?.env?.message : ''}
                                </Form.Help>
                              </Form.Control>
                            </Form.Field>
                          </PanelBody>
                        </Panel>
                      </VPCWrapper>
                    ))}
                  </>
                  <Form.Field className="is-flex mt-5" justifyContent="flex-end">
                    <Button
                      onClick={() => {
                        append({
                          env: 'DEV',
                          vpcId: '',
                          regionName: 'us-west-2',
                          subnetGroup: '',
                        })
                      }}
                      label="Add Another VPC"
                    />
                  </Form.Field>
                </PanelBody>
              </Panel>

              <Form.Field className="is-flex is-justify-content-space-between" mt={6}>
                <Button
                  onClick={() => {
                    if (canGoBack) {
                      navigator(-1)
                    } else {
                      navigator(ROUTES.listAdministrator)
                    }
                  }}
                  isDanger
                  label="Cancel"
                />
                <Button
                  isDisabled={!isValid || isLoading}
                  onClick={() => {
                    handleSubmit(onSubmit)()
                  }}
                  variant="primary"
                  label="Submit"
                />
              </Form.Field>
            </form>
          </PanelBody>
        </Panel>
      </Container>
    </AppLayout>
  )
}

export default CreateCloudAccount
