import React, {
  useEffect, useMemo, useState,
} from 'react'
import { Helmet } from 'react-helmet'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faCaretLeft, faEye } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, Link } from 'react-router-dom'
import styled from 'styled-components'
import * as bulmaToast from 'bulma-toast'
import classNames from 'classnames'
import { Button, Dialog } from '@bespin-ui/react-ui-components'
import { Container, Form, Icon } from '../../components/bulma'

import {
  ErrorBannerNotification, Label, Panel, PanelBody,
} from '../../components'
import AppLayout from '../../layouts'
import { ROUTES, TITLES } from '../../constants'
import { useCreateServer } from '../../hooks/api/server'
import { useFetchIsAdmin } from '../../hooks/api/admin'
import { useAuth } from '../../providers/AuthProvider'
import TextTooltip from '../../components/Tooltip'

const schema = yup.object({
  host: yup.string().required('is required').min(1),
  description: yup.string().required('is required').min(1),
  name: yup.string().required('is required').min(1),
  datacenterName: yup.string().required('is required').min(1),
  capacity: yup.number().positive().required('is required').min(1),
  state: yup.boolean().notRequired(),
  databaseVersion: yup.string().required('is required').min(1),
  customConnectionString: yup.string(),
  user: yup.string().required('is required')
    .matches(/^[a-zA-Z][0-9a-zA-Z_]*$/, { message: 'Must begin with alpha and only contain alphanumeric characters. Underscore is also allowed' })
    .min(4, 'Must be longer than 3 chars.')
    .max(128, 'Must be shorter than 129 characters.'),
  password: yup.string()
    .required('is required')
    .notOneOf([yup.ref('user')], 'cannot be username')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@&^#$!`*';\-%])[A-Za-z\d\\@&^#$!`*';\-%]{10,128}$/,
      { message: "Password should meet specified requirements. It should satisfy all of these combinations: length 10-128, [a-z], [A-Z], numbers, @, &, ^, #, $, !, `, *, ', -, %,;, cannot be username" },
    ),
  passwordConfirmation: yup.string().oneOf([yup.ref('password'), null], 'Password must match'),
  providerName: yup.string().required('is required'),
  customClientHost: yup.string(),
  pgPoolServers: yup.string(),
})

interface IFormInputs {
  host: string,
  description: string,
  datacenterName: string,
  name: string,
  capacity: number,
  state: boolean,
  databaseVersion: string,
  customConnectionString: string,
  user: string,
  password: string,
  passwordConfirmation: string,
  providerName: string,
  customClientHost: string,
  pgPoolServers: string
}

const tooltipStrongTextStyle = { outline: '#000 thin solid', display: 'table-cell', backgroundColor: '#D3D3D3' }
const TooltipTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    div {
        p{
            line-height: 20px;
        }
        margin-bottom: 10px;
    }
`

function CreateServerConfigure() {
  const { isAuthenticated } = useAuth()
  useFetchIsAdmin({ enabled: !isAuthenticated })

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  const {
    register, watch, formState: { errors }, handleSubmit, setError, clearErrors,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      host: '',
      datacenterName: '',
      name: '',
      description: '',
      state: false,
      databaseVersion: '',
      customConnectionString: '',
      customClientHost: '',
      pgPoolServers: '',
      user: '',
      password: '',
      passwordConfirmation: '',
      providerName: '',
    },
  })

  const values = watch()
  const { providerName = '' } = values

  const [showErrorModal, setShowErrorModal] = useState(false)

  const { mutate, isLoading, isError } = useCreateServer()

  useEffect(() => {
    if (isError) {
      setShowErrorModal(true)
    } else {
      setShowErrorModal(false)
    }
  }, [isError, isLoading])

  const navigate = useNavigate()

  const onCancel = () => {
    navigate(-1)
  }

  const onSubmit = () => {
    setIsModalOpen(true)
  }

  const addServer = () => {
    mutate({
      ...values,
      ssl: false,
      serviceNowId: 'adminServerTest',
    }, {
      onSuccess: () => {
        setIsModalOpen(false)
        bulmaToast.toast({
          message: `<b>${values.name}</b> Server Configuration is successfully created`,
          type: 'is-success',
          dismissible: true,
          duration: 6000,
          position: 'bottom-right',
          animate: { in: 'fadeIn', out: 'fadeOut' },
        })
        navigate(ROUTES.listServers, { replace: true })
      },
      onError: () => {
        setIsModalOpen(false)
      },
    })
  }

  const disableFormSubmitButton: boolean = useMemo(() => {
    if (Object.keys(errors).length > 0) {
      return true
    } if (values.host.length === 0
      || values.name.length === 0
      || values.providerName.length === 0
      || values.capacity <= 0
      || values.password.length === 0
      || values.passwordConfirmation.length === 0
      || values.description.length === 0
      || values.databaseVersion.length === 0
      || values.datacenterName.length === 0
    ) {
      return true
    }
    return false
  }, [errors, values])

  useEffect(() => {
    if (!errors.password && values.password === values.passwordConfirmation) {
      clearErrors('passwordConfirmation')
    } else if (errors.password && values.passwordConfirmation.trim() !== '') {
      setError('passwordConfirmation', { type: 'validate', message: 'Password must match' })
    }
  }, [values.password, values.passwordConfirmation, clearErrors, setError, errors.password])

  const closeModal = ():void => setIsModalOpen(false)

  return (
    <>
      <Helmet>
        <title>{TITLES.createServerConfiguration}</title>
      </Helmet>
      <AppLayout isLoading={isLoading}>
        <Dialog
          isOpen={isModalOpen}
          handleClose={closeModal}
        >
          <h4 className="title is-4 mt-5">
            Are you sure you want to add
            {' '}
            {values.name}
          </h4>
          <p className="subtitle is-6 mt-5 mb-5">
            You are about to add the server
            {' '}
            {values.name}
            , you can not undo the action, are you sure you want to continue?
          </p>

          <div className="is-flex pt-5 is-flex-direction-row-reverse">
            <Button isDisabled={isLoading} onClick={addServer} variant="primary" label="Add Server" />
            &nbsp;
            &nbsp;
            <Button data-testid="modalCancelButton" isDisabled={isLoading} onClick={closeModal} label="Cancel" />
          </div>
        </Dialog>
        <Container className="is-fluid">
          <h4 className="title is-4">
            <Link to={ROUTES.listServers} replace>
              <FontAwesomeIcon className="pr-1" icon={faCaretLeft} />
            </Link>
            <FontAwesomeIcon className="pr-3" icon={faDatabase} />
            Servers &gt; Create Server Configuration
          </h4>
          {showErrorModal && (
            <ErrorBannerNotification
              dismissButtonCallback={() => { setShowErrorModal(false) }}
              message="Unexpected error occurred!. Check your inputs and try again or contact DBaaS support."
            />
          )}
          <Panel title="Create Server Configuration">
            <PanelBody>
              <form>
                <Form.Field>
                  <Label
                    label="Host"
                    for="host"
                    required
                    popover={(
                      <TextTooltip>
                        <TooltipTextContainer>
                          <p><b>This field value should be in following format:</b></p>
                          <div>
                            <p>If provider is MYSQL: [hostname]</p>
                            <p>Example: </p>
                            <p style={tooltipStrongTextStyle}>
                              dbaasmydbdev01.starwave.com
                            </p>
                          </div>
                          <div>
                            <p>If provider is MSSQL: </p>
                            <p> [hostname]:[portnumber];databaseName=dbaas</p>
                            <p> Example:</p>
                            <p style={tooltipStrongTextStyle}>
                              TDSSQLAGL15.mgmt.prod:1433;databaseName=dbaas
                            </p>
                          </div>
                          <div>
                            <p> If provider is PostgreSQL: </p>
                            <p>[hostname]:[portnumber]/postgres</p>
                            <p>Example:</p>
                            <p style={tooltipStrongTextStyle}>
                              qn7dsc01pgdb01.starwave.com:5432/postgres
                            </p>
                          </div>
                        </TooltipTextContainer>
                      </TextTooltip>
                    )}
                  />
                  <Form.Control>
                    <input className={classNames('input', { 'is-danger': errors.host != null })} {...register('host')} id="host" />
                  </Form.Control>
                  <Form.Help color="danger">{errors.host ? errors.host.message : ''}</Form.Help>
                </Form.Field>
                <Form.Field>
                  <Label label="Provider Name" for="providerName" required />
                  <Form.Control>
                    <div className="select is-fullwidth">
                      <select id="providerName" {...register('providerName')}>
                        <option disabled={providerName.length > 0} value="">{' '}</option>
                        <option value="MSSQL">MSSQL</option>
                        <option value="MYSQL">MYSQL</option>
                        <option value="PostgreSQL">PostgreSQL</option>
                      </select>
                    </div>
                  </Form.Control>
                  <Form.Help color="danger">{errors.providerName ? errors.providerName.message : ''}</Form.Help>
                </Form.Field>
                <Form.Field>
                  <Label
                    label="Datacenter Name"
                    for="datacenterName"
                    required
                    popover={(
                      <TextTooltip>
                        A connectivity request to the database VIP can be
                        made from anywhere within the Disney Global Network.
                      </TextTooltip>
                    )}
                  />
                  <Form.Control>
                    <input className={classNames('input', { 'is-danger': errors.datacenterName != null })} {...register('datacenterName')} id="datacenterName" />
                  </Form.Control>
                  <Form.Help color="danger">{errors.datacenterName ? errors.datacenterName.message : ''}</Form.Help>
                </Form.Field>
                <Form.Field>
                  <Label
                    label="Name"
                    for="name"
                    required
                    popover={(
                      <TextTooltip>
                        This field value will be used to display to DBaaS users as &apos;Service
                        Offering&apos; drop down field to select in database creation page.
                      </TextTooltip>
                    )}
                  />
                  <Form.Control>
                    <input className={classNames('input', { 'is-danger': errors.name != null })} {...register('name')} id="name" />
                  </Form.Control>
                  <Form.Help color="danger">{errors.name ? errors.name.message : ''}</Form.Help>
                </Form.Field>
                <Form.Field>
                  <Label label="Description" for="description" required />
                  <Form.Control>
                    <input className={classNames('input', { 'is-danger': errors.description != null })} {...register('description')} id="description" />
                  </Form.Control>
                  <Form.Help color="danger">{errors.description ? errors.description.message : ''}</Form.Help>
                </Form.Field>
                <Form.Field>
                  <Label label="Disk Capacity (In GB)" for="capacity" required />
                  <Form.Control>
                    <input className={classNames('input', { 'is-danger': errors.capacity != null })} type="number" {...register('capacity', { valueAsNumber: true })} id="capacity" />
                  </Form.Control>
                  <Form.Help color="danger">{errors.capacity ? errors.capacity.message : ''}</Form.Help>
                </Form.Field>
                <Form.Field>
                  <Label label="Database Version" for="databaseVersion" required />
                  <Form.Control>
                    <input className={classNames('input', { 'is-danger': errors.databaseVersion != null })} {...register('databaseVersion')} id="databaseVersion" />
                  </Form.Control>
                  <Form.Help color="danger">{errors.databaseVersion ? errors.databaseVersion.message : ''}</Form.Help>
                </Form.Field>

                <Form.Field>
                  <Label label="Custom Connection String" for="customConnectionString" />
                  <Form.Control>
                    <input className="input" {...register('customConnectionString')} id="customConnectionString" />
                  </Form.Control>
                </Form.Field>

                <Form.Field>
                  <Label label="Custom Client Host" for="customClientHost" />
                  <Form.Control>
                    <input className="input" {...register('customClientHost')} id="customClientHost" />
                  </Form.Control>
                </Form.Field>

                <Form.Field>
                  <Label label="Custom Server Config (only used for PGSQL)" for="pgPoolServers" />
                  <Form.Control>
                    <input className="input" {...register('pgPoolServers')} id="pgPoolServers" />
                  </Form.Control>
                </Form.Field>

                <Form.Field>
                  <Label
                    label="User"
                    for="user"
                    required
                    popover={(
                      <TextTooltip>
                        This is the credentials will be used by DBaaS api service
                        to do database create/drop/reset password/add user/remove user etc.
                      </TextTooltip>
                    )}
                  />
                  <Form.Control>
                    <input className={classNames('input', { 'is-danger': errors.user != null })} {...register('user')} id="user" />
                  </Form.Control>
                  <Form.Help color="danger">{errors.user ? errors.user.message : ''}</Form.Help>
                </Form.Field>

                <Form.Field>
                  <Label
                    label="Password"
                    for="password"
                    required
                    popover={(
                      <TextTooltip>
                        This is the credentials will be used by DBaaS api service
                        to do database create/drop/reset password/add user/remove user etc.
                      </TextTooltip>
                    )}
                  />
                  <Form.Control>
                    <input type={showPassword ? 'text' : 'password'} className={classNames('input', { 'is-danger': errors.password != null })} {...register('password')} id="password" />
                    <Icon align="right">
                      <FontAwesomeIcon icon={faEye} color="rgb(74, 74, 74);" cursor="pointer" pointerEvents="initial" onClick={() => setShowPassword(!showPassword)} />
                    </Icon>
                  </Form.Control>
                  {errors.password && <Form.Help color="danger">{errors.password.message}</Form.Help>}
                </Form.Field>

                <Form.Field>
                  <Label label="Password Confirmation" for="passwordConfirmation" required />
                  <Form.Control>
                    <input type={showConfirmPassword ? 'text' : 'password'} className={classNames('input', { 'is-danger': errors.passwordConfirmation != null })} {...register('passwordConfirmation')} id="passwordConfirmation" />
                    <Icon align="right">
                      <FontAwesomeIcon icon={faEye} color="rgb(74, 74, 74);" cursor="pointer" pointerEvents="initial" onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                    </Icon>
                  </Form.Control>
                  {errors.passwordConfirmation && <Form.Help color="danger">{errors.passwordConfirmation.message}</Form.Help>}
                </Form.Field>

                <Form.Field horizontal className="is-align-content-center mb-0">
                  <Form.Control>
                    <label className="checkbox mt-5" htmlFor="state">
                      <span className="title is-6">State &nbsp;</span>
                      <input type="checkbox" id="state" {...register('state')} />
                      &nbsp; Active
                    </label>
                  </Form.Control>
                </Form.Field>

                <Form.Field className="is-flex is-justify-content-space-between mt-5">
                  <Button
                    label="Cancel"
                    onClick={onCancel}
                  />
                  <Button
                    isDisabled={disableFormSubmitButton}
                    label="Submit"
                    variant="primary"
                    onClick={handleSubmit(onSubmit)}
                  />
                </Form.Field>
              </form>
            </PanelBody>
          </Panel>
        </Container>
      </AppLayout>
    </>
  )
}

export default CreateServerConfigure
