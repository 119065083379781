import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { usePopperTooltip } from 'react-popper-tooltip'
import 'react-popper-tooltip/dist/styles.css'
import { Form } from './bulma'
import ToolTipIcon from './ToolTipIcon'

interface ILabel {
  label: string,
  children?: React.ReactNode,
  required?: boolean,
  for?: string,
  className?: string,
  style?: React.CSSProperties,
  popover?: React.ReactNode,
  marginless?: boolean,
  isInteractivePopover?: boolean,
}

function Label(props: ILabel) {
  const [controlledVisible, setControlledVisible] = useState(false)

  const {
    label, className, for: htmlFor, popover, required, style, marginless, children,
    isInteractivePopover,
  } = props

  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'click',
    visible: controlledVisible,
    placement: 'right',
    onVisibleChange: setControlledVisible,
    interactive: isInteractivePopover,
  })

  useEffect(() => {
    const handleKeyDown = ({ key }: KeyboardEvent) => {
      if (key === 'Escape') {
        setControlledVisible(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <Form.Label htmlFor={htmlFor} style={style} className={classNames('title is-6', { 'mt-5': !marginless }, className)}>
      {label}
      {(required === true)
        && <span style={{ color: '#f14668' }}>&nbsp;*&nbsp;</span>}
      {popover != null && (
        <ToolTipIcon ref={setTriggerRef} />
      )}

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          {popover}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}

      {children}
    </Form.Label>
  )
}

Label.defaultProps = {
  required: undefined,
  for: undefined,
  className: undefined,
  style: undefined,
  popover: undefined,
  marginless: false,
  children: undefined,
  isInteractivePopover: false,
}

export default Label
