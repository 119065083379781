import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'
import { Button } from '@bespin-ui/react-ui-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { BiPlus } from 'react-icons/bi'
import { ColumnDef } from '@tanstack/table-core'
import { ActionButtons, Table } from '../../components'
import { Columns, Container } from '../../components/bulma'
import AppLayout from '../../layouts'
import { ROUTES } from '../../constants'
import { useFetchOrganizations, IOrganization } from '../../hooks/api/organizations'

function ListOrganization() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { isFetching: isLoading, data: response } = useFetchOrganizations()
  const data = response?.data ?? []
  const columns: ColumnDef<IOrganization>[] = useMemo(() => [
    {
      header: 'Org name',
      accessorKey: 'name',
      id: 'name',
    },
    {
      header: 'Exec Email',
      accessorKey: 'execContactEmail',
      id: 'execContactEmail',
    },
    {
      header: 'Tech Email',
      accessorKey: 'techContactEmail',
      id: 'techContactEmail',
    },
    {
      header: 'Created By',
      accessorKey: 'createdBy',
      id: 'createdBy',
    },
    {
      header: 'Created Date',
      accessorKey: 'createdDate',
      id: 'createdDate',
    },
    {
      header: 'View',
      id: 'view',
      cell: ({ row }) => ActionButtons(
        {
          ...{
            viewCallback: () => {
              navigate(`${pathname}/${row.original.id}/view`, { state: row.original })
            },
          },
        },
      ),
      meta: { width: '73px' },
    },
  ], [navigate, pathname])

  const navigation = useNavigate()

  return (
    <AppLayout isLoading={isLoading}>
      <Container className="is-fluid pr-0 pl-0">
        <Columns multiline={false}>
          <Columns.Column>
            <h4 className="title is-4">
              <FontAwesomeIcon className="pr-3" icon={faDatabase} />
              Organizations
            </h4>
          </Columns.Column>
        </Columns>
        <Columns multiline={false}>
          <Columns.Column className="is-flex is-flex-direction-row-reverse">
            <Button
              onClick={() => {
                navigation(ROUTES.createOrganization)
              }}
              size="small"
              variant="primary"
              label="Create Organization"
              icon={<BiPlus />}
            />
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Table columns={columns} data={data} />
          </Columns.Column>
        </Columns>
      </Container>
    </AppLayout>
  )
}

export default ListOrganization
