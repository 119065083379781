import React from 'react'
import { SideNav, SideNavItem } from '@bespin-ui/react-ui-components'
import {
  BiBuilding,
  BiConversation, BiServer, BiSpreadsheet, BiUserPlus,
} from 'react-icons/bi'
import { AiOutlineProject } from 'react-icons/ai'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { AppSpinner } from '../components'
import { Columns } from '../components/bulma'
import ROUTES from '../constants/ROUTES'
import { useAuth } from '../providers/AuthProvider'
import TopNav from '../components/TopNav'
import AdminContainer from './AppLayoutStyles'

const LinkWrapper = styled(Link)`
  color: var(--bespin-lib-color-sidenav-text);
  outline: transparent;
  outline-width: 0px;
  :hover {
    color: var(--bespin-lib-color-sidenav-text);
  }
  text-decoration: none;
  [data-testid="bespin-sidenavitem-nolink"] {
    cursor: pointer;
  }
  `

const SideNavItemHeader = styled.div`
 li {
   div {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    font-weight: 500;
    cursor: default;
    :hover {
      color: unset;
      background-color: unset;
      opacity: unset;
      font-weight: 500;
      font-size: var(--bespin-lib-measure-14);
    }
  }
}
`

function AppLayout({ children, isLoading }: { children: React.ReactNode, isLoading?: boolean }) {
  const { isAdmin, isAuthenticated } = useAuth()
  const loading = (isLoading ?? false)

  return (
    <AppSpinner isLoading={loading}>
      {isAuthenticated
          && (
            <AdminContainer>
              <TopNav />
              <Columns multiline={false} display="flex" flexWrap="nowrap" justifyContent="center" className="pr-4">
                {isAdmin
                  && (
                    <Columns.Column size={2}>
                      <SideNav data-testid="sidebar">

                        <SideNavItemHeader>
                          <SideNavItem label="RDS / DPC" />
                        </SideNavItemHeader>

                        <LinkWrapper to={ROUTES.listServers}>
                          <SideNavItem
                            icon={<BiServer />}
                            label="Servers"
                          />
                        </LinkWrapper>

                        <LinkWrapper to={ROUTES.listDatabases}>
                          <SideNavItem
                            icon={<BiServer />}
                            label="Databases"
                          />
                        </LinkWrapper>

                        <>
                          <SideNavItemHeader>
                            <SideNavItem label="MongoDB" />
                          </SideNavItemHeader>
                          <LinkWrapper to={ROUTES.listOrganization}>
                            <SideNavItem
                              icon={<BiBuilding />}
                              label="Organizations"
                            />
                          </LinkWrapper>

                          <SideNavItem label="Projects" icon={<AiOutlineProject />} />

                          <LinkWrapper to={ROUTES.listClusters}>
                            <SideNavItem
                              icon={<BiServer />}
                              label="Clusters"
                            />
                          </LinkWrapper>
                        </>

                        <SideNavItemHeader>
                          <SideNavItem label="General" />
                        </SideNavItemHeader>

                        <LinkWrapper to={ROUTES.listAdministrator}>
                          <SideNavItem
                            icon={<BiUserPlus />}
                            label="Administration"
                          />
                        </LinkWrapper>

                        <LinkWrapper to={ROUTES.listEvents}>
                          <SideNavItem
                            icon={<BiConversation />}
                            label="Events"
                          />
                        </LinkWrapper>
                        <LinkWrapper to={ROUTES.listLogs}>
                          <SideNavItem
                            icon={<BiSpreadsheet />}
                            label="Logs"
                          />
                        </LinkWrapper>
                      </SideNav>
                    </Columns.Column>
                  )}
                <Columns.Column size={10}>{children}</Columns.Column>
              </Columns>
            </AdminContainer>
          )}
    </AppSpinner>
  )
}

AppLayout.defaultProps = {
  isLoading: false,
}

export default AppLayout
