import React, { useMemo } from 'react'
import {
  Routes as BaseRoutes, Route, Navigate,
} from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { openIdConfig } from './config/config'
import {
  CreateDatabase,
  Home,
  ListDBaaS,
  CreateDatabaseConfirm,
  ListServer,
  CreateServerConfiguration,
  CreateAdministrator,
  ListAdministrator,
  ListEvents,
  ListServiceLogs,
  ServerDetailsView,
  ViewDatabaseDetails,
  ViewEventDetails,
  ViewServiceLogDetails,
  EditDatabase,
  EditServerById,
  EditAdministrator,
  CreateCloudAccount,
  ListOrganization,
  ListClusters,
  CreateOrganization,
  OrgViewDetailsPage,
} from './pages'
import ROUTES from './constants/ROUTES'
import { useAuth } from './providers/AuthProvider'
import { Spacer } from './styles/Home.module'
import Redirect from './pages/redirect/Redirect'
import Maintenance from './components/maintenance'
import useMaintenanceStatus from './hooks/maintenance'
import { Button, Container } from './components/bulma'
import CreateCluster from './pages/createMongoDBCluster'

function ProtectedRoute({
  children,
  isAdminProtected,
}: { children: JSX.Element, isAdminProtected?: boolean }) {
  const { hasError, isAdmin, isAuthenticated } = useAuth()
  const client = useQueryClient()
  const error = client.getQueryState('isAdmin')?.error

  const { isUnderMaintenance } = useMaintenanceStatus()

  const isTimedOut = useMemo(() => {
    if (error) {
      const asAxiosError = error as AxiosError
      const errorObject: { message?: string } = asAxiosError.toJSON()
      if (typeof errorObject.message === 'string') {
        const { message } = errorObject
        if (message.includes('timeout')) {
          return true
        }
      }
    }
    return false
  }, [error])

  if (hasError) {
    return (
      <Spacer>
        <Container alignItems="center" display="flex" flexDirection="column">
          <h1 className="title">Error</h1>

          {(!isAuthenticated && hasError)
            ? (
              <h4>
                {isTimedOut && ' Connection timed out. '}
                Are you logged into the VPN with stable internet access?
              </h4>
            )
            : <h4>There was an unspecified error while loading this page.</h4>}

          <h6 className="is-size-6">
            If this problem persists please contact DBaaS support via
            {' '}
            <a href="https://disney.slack.com/archives/C02SWCV9T6F" target="_blank" rel="noreferrer">slack</a>
            {' '}
            channel.
          </h6>

          <h4 className="mt-4">
            <Button
              type="button"
              color="ghost"
              paddingless
              marginless
              style={{ height: 'max-content' }}
              onClick={() => { window.location.reload() }}
            >
              Reload
            </Button>
            {' '}
            or try again later
          </h4>

        </Container>
      </Spacer>
    )
  }

  if (isUnderMaintenance) {
    return <Maintenance />
  }

  if (!isAdmin && isAuthenticated && isAdminProtected) {
    return <Navigate to={ROUTES.listDatabases} replace />
  }

  return children
}

ProtectedRoute.defaultProps = {
  isAdminProtected: true,
}

function Routes() {
  const { isUnderMaintenance } = useMaintenanceStatus()
  return (
    <BaseRoutes>
      <Route path="/" element={isUnderMaintenance ? <Maintenance /> : <Home />} />
      <Route
        path={ROUTES.editDatabaseById}
        element={(
          <ProtectedRoute
            isAdminProtected={false}
          >
            <EditDatabase />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.databaseCreatedConfirm}
        element={(
          <ProtectedRoute isAdminProtected={false}>
            <CreateDatabaseConfirm />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.createDatabase}
        element={(
          <ProtectedRoute
            isAdminProtected={false}
          >
            <CreateDatabase />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.createDatabaseEdit}
        element={(
          <ProtectedRoute
            isAdminProtected={false}
          >
            <CreateDatabase />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.listDatabases}
        element={(
          <ProtectedRoute
            isAdminProtected={false}
          >
            <ListDBaaS />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.viewDatabaseDetails}
        element={(
          <ProtectedRoute
            isAdminProtected={false}
          >
            <ViewDatabaseDetails />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.editServerById}
        element={(
          <ProtectedRoute>
            <EditServerById />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.listServers}
        element={(
          <ProtectedRoute>
            <ListServer />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.viewServer}
        element={(
          <ProtectedRoute>
            <ServerDetailsView />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.createServerConfigure}
        element={(
          <ProtectedRoute>
            <CreateServerConfiguration />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.listAdministrator}
        element={(
          <ProtectedRoute>
            <ListAdministrator />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.createAdministrator}
        element={(
          <ProtectedRoute>
            <CreateAdministrator />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.createCloudAccount}
        element={(
          <ProtectedRoute>
            <CreateCloudAccount />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.editAdministrator}
        element={(
          <ProtectedRoute>
            <EditAdministrator />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.listEvents}
        element={(
          <ProtectedRoute>
            <ListEvents />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.viewEventDetails}
        element={(
          <ProtectedRoute>
            <ViewEventDetails />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.listLogs}
        element={(
          <ProtectedRoute>
            <ListServiceLogs />
          </ProtectedRoute>
        )}
      />
      <Route
        path={ROUTES.viewLogDetails}
        element={(
          <ProtectedRoute>
            <ViewServiceLogDetails />
          </ProtectedRoute>
        )}
      />
      <Route
        path={openIdConfig.redirectUri}
        element={(
          <ProtectedRoute>
            <Redirect />
          </ProtectedRoute>
        )}
      />

      <Route
        path={ROUTES.listOrganization}
        element={(
          <ProtectedRoute>
            <ListOrganization />
          </ProtectedRoute>
        )}
      />

      <Route
        path={ROUTES.listClusters}
        element={(
          <ProtectedRoute>
            <ListClusters />
          </ProtectedRoute>
        )}
      />

      <Route
        path={ROUTES.viewOrganizationDetails}
        element={(
          <ProtectedRoute>
            <OrgViewDetailsPage />
          </ProtectedRoute>
        )}
      />

      <Route
        path={ROUTES.createOrganization}
        element={(
          <ProtectedRoute>
            <CreateOrganization />
          </ProtectedRoute>
        )}
      />

      <Route
        path={ROUTES.createMongoDBCluster}
        element={(
          <ProtectedRoute>
            <CreateCluster />
          </ProtectedRoute>
        )}
      />

      <Route path={ROUTES.basePath} element={<Navigate to={ROUTES.listDatabases} replace />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </BaseRoutes>
  )
}

export default Routes
